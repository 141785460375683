import { classNames } from 'primereact/utils'
import React, { useEffect, useState } from 'react'
import DateInput from '../../../../../components/inputs/DateInput'
import NumberInput from '../../../../../components/inputs/NumberInput'
import PlacaInput from '../../../../../components/inputs/PlacaInput'
import SelectInput from '../../../../../components/inputs/SelectInput'
import TextAreaInput from '../../../../../components/inputs/TextAreaInput'
import TextInput from '../../../../../components/inputs/TextInput'
import SearchClearInput from '../../../../../components/inputs/searchInput/SearchClearInput'
import SearchInput from '../../../../../components/inputs/searchInput/SearchInput'
import FieldErrorMessage from '../../../../../components/utils/FieldErrorMessage'
import { showErrorMessage, showSuccessMessage, showWarnMessage } from '../../../../../components/utils/Message'
import RequiredLabel from '../../../../../components/utils/RequiredLabel'
import { getModeloDTO } from '../../../../../dtos/cadastro/veiculo/ModeloDTO'
import CommonHelper from '../../../../../helpers/CommonHelper'
import { formatCurrencyToNumber, formatToCurrency } from '../../../../../helpers/formaters'
import { useValidateInput } from '../../../../../helpers/useValidateInput'
import { VendedorModel } from '../../../../../models/cadastro/pessoa/VendedorModel'
import UsuarioService from '../../../../../services/cadastro/pessoa/UsuarioService'
import VendedoresService from '../../../../../services/cadastro/pessoa/VendedoresService'
import ModeloService from '../../../../../services/cadastro/veiculo/ModeloService'
import VeiculoService from '../../../../../services/cadastro/veiculo/VeiculoService'
import NegociacaoService from '../../../../../services/vendas/negociacao/NegociacaoService'
import SelecionarModelosReferenciaModal from '../SelecionarModelosReferenciaModal'
import SelecionarModelosFipeModal from '../SelecionarModeloFipeModal'
import AvaliacaoService from '../../../../../services/cadastro/veiculo/AvaliacaoService'
import ConectaCarrosService from '../../../../../services/Integracao/conectaCarros/ConectaCarrosService'

const DadosPrincipaisAva = ({ form, edicaoHabilitada }) => {
  const { isFormFieldValid, getFormErrorMessage } = useValidateInput(form)

  const [formasAquisicao, setFormasAquisicao] = useState([])
  const [sugestaoModelos, setSugestaoModelos] = useState([])
  const [sugestaoAvaliadores, setSugestaoAvaliadores] = useState([])
  const [visibleSelecionarModelosReferenciasModal, setVisibleSelecionarModelosReferenciasModal] = useState(false)
  const [visibleSelecionarModelosFipeModal, setVisibleSelecionarModelosFipeModal] = useState(false)

  useEffect(async () => {
    await getFormasAquisicao()
    const usuarioLogado = await UsuarioService.getUsuarioLogado()
    if (usuarioLogado.usuario.pessoa?.vendedor?.ativo_ven) {
      form.setFieldValue('vendedor', usuarioLogado.usuario.pessoa?.vendedor?.codigo_ven)
      form.setFieldValue('vendedor.codigo_ven', usuarioLogado.usuario.pessoa.codigo_pes)
      form.setFieldValue('vendedor.nomraz_pes', usuarioLogado.usuario.pessoa?.nomraz_pes)
    }
  }, [])

  const getFormasAquisicao = async () => {
    try {
      await NegociacaoService.getFormaAquisicao().then(formasAquisicao => setFormasAquisicao(formasAquisicao))
    } catch (error) {
      showErrorMessage(error.message || 'Ocorreu um erro inesperado ao buscar as formas de aquisição!')
    }
  }

  const sugerirModelos = async () => {
    try {
      await VeiculoService.filterModels(form.values.modelo.descri_mod).then(modelos => setSugestaoModelos(modelos))
    } catch (error) {
      showErrorMessage(error.message || 'Ocorreu um erro inesperado ao sugerir um modelo!')
    }
  }

  const sugerirAvaliadores = async () => {
    try {
      const vendedoresService = new VendedoresService()
      const avaliadores = await vendedoresService.filterAutoComplete(`nomraz_pes=${form.values.vendedor.nomraz_pes}`)

      if (avaliadores.length < 1) {
        showWarnMessage('Não foi encontrado nenhum avaliador com esse nome!')
      }

      setSugestaoAvaliadores(avaliadores)
    } catch (error) {
      showErrorMessage(error.message || 'Ocorreu um erro inesperado ao sugerir um avaliador!')
    }
  }

  const handleChangeCurrency = (e) => {
    form.setFieldValue(e.target.name, formatCurrencyToNumber(e.target.value))
  }

  const handleChangePlaca = async (value) => {
    const placa = CommonHelper.clearDigitsSpecialCharsExceptLetters(value)
    form.setFieldValue('placa_ava', placa)
    if (edicaoHabilitada && placa?.length === 7) {
      await buscarPlaca(placa)
    }
  }

  const buscarPlaca = async (placaVei) => {
    try {
      const veiculo = await VeiculoService.getByPlate(placaVei, 0)
      let fipcoc
      if (veiculo) {
        showSuccessMessage(`Os dados foram obtidos do veículo ${veiculo.codigo_vei}`)
        form.setFieldValue('codmod_ava', veiculo.codmod_vei)
        form.setFieldValue('modelo', veiculo.modelo)
        form.setFieldValue('anomod_ava', veiculo.anomod_vei)
        form.setFieldValue('anofab_ava', veiculo.anofab_vei)
        form.setFieldValue('chassi_ava', veiculo.chassi_vei)
        form.setFieldValue('km_ava', veiculo.km_vei)
        form.setFieldValue('nomraz_ava', veiculo.proprietario.nomraz_pes)
        form.setFieldValue('cgccpf_ava', veiculo.proprietario.cgccpf_pes)
        form.setFieldValue('telcel_ava', veiculo.proprietario.telcel_pes)
        form.setFieldValue('email_ava', veiculo.proprietario.email_pes)
        form.setFieldValue('codcoc_ava', veiculo.codcoc_vei)
        form.setFieldValue('descoc_ava', veiculo.descoc_vei)
        form.setFieldValue('tivcoc_ava', veiculo.tivcoc_vei)
        form.setFieldValue('fipcoc_ava', veiculo.fipcoc_vei)
        form.setFieldValue('codfip_ava', veiculo.codfip_vei)
        form.setFieldValue('desfip_ava', veiculo.desfip_vei)
        form.setFieldValue(
          'proprietario',
          veiculo.proprietario || { nomraz_pes: '' }
        )
        fipcoc = veiculo.fipcoc_vei
      } else {
        const avaliacao = await AvaliacaoService.getAvaliacaoCompletaPorPlaca(placaVei)
        if (avaliacao) {
          showSuccessMessage(`Os dados foram obtidos da avaliação ${avaliacao.codigo_ava}`)

          form.setFieldValue('chassi_ava', avaliacao.chassi_ava)
          form.setFieldValue(
            'proprietario',
            avaliacao.proprietario || { nomraz_pes: '' }
          )
          form.setFieldValue('modelo', avaliacao.modelo || { descri_mod: '' })
          form.setFieldValue('km_ava', avaliacao.km_ava)
          form.setFieldValue('codfip_ava', avaliacao.codfip_ava)
          form.setFieldValue('anomod_ava', avaliacao.anomod_ava)
          form.setFieldValue('anofab_ava', avaliacao.anofab_ava)
          form.setFieldValue('cor', avaliacao.corPredominante || {})
          form.setFieldValue('combustivel', avaliacao.combustivel || {})
          form.setFieldValue('lisace_ava', avaliacao.lisace_ava)
          form.setFieldValue('codcoc_ava', avaliacao.codcoc_ava)
          form.setFieldValue('descoc_ava', avaliacao.descoc_ava)
          form.setFieldValue('tivcoc_ava', avaliacao.tivcoc_ava)
          form.setFieldValue('fipcoc_ava', avaliacao.fipcoc_ava)
          form.setFieldValue('codfip_ava', avaliacao.codfip_ava)
          form.setFieldValue('desfip_ava', avaliacao.desfip_ava)
          fipcoc = avaliacao.fipcoc_ava
        }
      }

      if (fipcoc) {
        const valorFipeAtual = await ConectaCarrosService.getValorFipeAtual(fipcoc)
        if (valorFipeAtual?.data) {
          form.setFieldValue('datfip_ava', valorFipeAtual.data.data_ultima_alteracao)
          form.setFieldValue('valfip_ava', valorFipeAtual.data.valor_fipe)
        }
      }
    } catch (error) {
      showErrorMessage(error.message || 'Ocorreu um erro inesperado ao tentar buscar a placa!')
    }
  }

  const onClearModeloReferencia = () => {
    form.setFieldValue('tivcoc_ava', null)
    form.setFieldValue('codcoc_ava', null)
    form.setFieldValue('descoc_ava', '')
    form.setFieldValue('fipcoc_ava', null)
    form.setFieldValue('desfip_ava', '')
  }

  const onClearModeloFipe = () => {
    form.setFieldValue('fipcoc_ava', null)
    form.setFieldValue('desfip_ava', '')
  }

  const handleModeloConfirmado = (avaliacao) => {
    form.setValues(avaliacao)
  }

  const abrirModalModelosFipe = () => {
    if (!form.values.codcoc_ava) {
      showErrorMessage('Informe o modelo de referência de integração primeiro!')
      return false
    }
    setVisibleSelecionarModelosFipeModal(true)
  }

  return (
    <div className="formgrid grid fluid">
      <div className={' field col-12 md:col-4'}>
        <RequiredLabel label="Avaliador" valid={isFormFieldValid('vendedor.codigo_ven')} />
        <SearchInput
          field="nomraz_pes"
          value={form.values.vendedor?.nomraz_pes && form.values.vendedor}
          suggestions={sugestaoAvaliadores}
          completeMethod={sugerirAvaliadores}
          onChange={(e) => form.setFieldValue('vendedor.nomraz_pes', e.target.value)}
          onSelect={(e) => form.setFieldValue('vendedor', e.value)}
          placeholder="Digite para pesquisar"
          disabled={!edicaoHabilitada}
          className={classNames({ 'p-invalid': isFormFieldValid('vendedor.codigo_ven') }, 'inputfield w-full')}
          onBlur={() => form.setFieldTouched('vendedor.codigo_ven')}
          // FiltroModal
          filtrotitle="Pesquisa de vendedor"
          service={VendedoresService}
          model={VendedorModel}
          primarykeyname="codigo_ven"
          columns={[
            { campo: 'codigo_ven', nome: 'Código' },
            { campo: 'nomraz_pes', nome: 'Nome' }
          ]}
        />
        <FieldErrorMessage message={getFormErrorMessage('vendedor.codigo_ven')} />
      </div>
      <div className={'md:col-4 field col-12'}>
        <SelectInput
          label="Aquisição"
          options={formasAquisicao}
          value={form.values.formaAquisicao}
          onChange={form.handleChange}
          name="formaAquisicao"
          dataKey="codigo_foa"
          optionLabel="descri_foa"
          disabled={!edicaoHabilitada}
          className="inputfield w-full"
          placeholder="Forma de aquisição"
          showClear={form.values.codigo_foa}
        />
      </div>
      <div className={'md:col-4 field col-12'}>
        <RequiredLabel label="Vencimento" valid={isFormFieldValid('datval_ava')} />
        <DateInput
          name="datval_ava"
          value={form.values.datval_ava}
          onChange={form.handleChange}
          placeholder="Data do vencimento"
          disabled={!edicaoHabilitada}
          className={classNames({ 'p-invalid': isFormFieldValid('datval_ava') }, 'inputfield w-full')}
          onBlur={() => form.setFieldTouched('datval_ava')}
        />
        <FieldErrorMessage message={getFormErrorMessage('datval_ava')} />
      </div>
      <div className="form-divider">
      </div>
      <div className={'md:col 6 field col-12 text-left no-padding'}>
        <div className={(CommonHelper.isDesktop() ? 'pr-6' : '') + ' field col-12 md:col-12 '}>

          <div className={(CommonHelper.isDesktop() ? 'flex flex-row' : '')}>
            <div className={(CommonHelper.isDesktop() ? 'w-50' : '')}>
              <h1 className={(CommonHelper.isDesktop() ? 'my-4 px-2' : 'my-4 px-2')}>Informações do Veículo</h1>
              <div className={(CommonHelper.isDesktop() ? '' : 'margin-y') + 'field md:col-18 sombra-caixa'}>

                <div className={(CommonHelper.isDesktop() ? 'flex' : 'grid')} >
                  <div className={(CommonHelper.isDesktop() ? 'field md:col-4' : 'md:col-4 field col-12 px-3')} >
                    <RequiredLabel label="Placa" valid={isFormFieldValid('placa_ava')} />
                    <PlacaInput
                      name="placa_ava"
                      className={classNames({ 'p-invalid': isFormFieldValid('placa_ava') }, 'inputfield w-full')}
                      value={form.values.placa_ava}
                      onChange={async (value) => await handleChangePlaca(value)}
                      disabled={!edicaoHabilitada}
                      placeholder="Placa"

                    />
                    <FieldErrorMessage message={getFormErrorMessage('vendedor.codigo_ven')} />
                  </div>
                  <div className={(CommonHelper.isDesktop() ? 'field md:col-4' : 'md:col-4 field col-12 px-3')}>

                    <NumberInput
                      label="Ano modelo"
                      placeholder="Ano modelo"
                      value={form.values.anomod_ava}
                      onChange={(e) => form.setFieldValue('anomod_ava', e.value)}
                      disabled={!edicaoHabilitada}
                      maxLength={4}
                      className="inputfield w-full"
                    />
                  </div>
                  <div className={(CommonHelper.isDesktop() ? 'field md:col-3' : 'md:col-4 field col-12 px-3')}>
                    <NumberInput
                      label="Km atual"
                      placeholder="KM"
                      className="inputfield w-full"
                      value={form.values.km_ava}
                      onChange={(e) => form.setFieldValue('km_ava', e.value)}
                      disabled={!edicaoHabilitada}
                    />
                  </div>
                </div>
                <div className='field md:col-11'>
                  <SearchInput
                    label="Modelo"
                    field="descri_mod"
                    value={form.values.modelo.descri_mod && form.values.modelo}
                    suggestions={sugestaoModelos}
                    completeMethod={sugerirModelos}
                    onChange={(e) => form.setFieldValue('modelo.descri_mod', e.target.value)}
                    onSelect={(e) => form.setFieldValue('modelo', e.value)}
                    placeholder="Digite para pesquisar"
                    disabled={!edicaoHabilitada}
                    className="inputfield w-full"
                    // FiltroModal
                    primarykeyname="codigo_mod"
                    filtrotitle="Pesquisa de modelo"
                    service={ModeloService}
                    model={getModeloDTO}
                    columns={[
                      { campo: 'codigo_mod', nome: 'Código' },
                      { campo: 'descri_mod', nome: 'Descrição' },
                      { campo: 'marca.descri_mar', nome: 'Marca' }
                    ]}
                  />
                </div>
                <div className='field md:col-11'>
                  <SearchClearInput
                    label="Modelo de referência ConectaCarros"
                    field="descoc_ava"
                    value={form.values.descoc_ava}
                    onClear={onClearModeloReferencia}
                    onSearch={() => setVisibleSelecionarModelosReferenciasModal(true)}
                    placeholder="Clique na lupa para pesquisar"
                    disabled={!edicaoHabilitada}
                    disabledAutoComplete={true}
                    className="inputfield w-full"
                  >
                    <SelecionarModelosReferenciaModal
                      visible={visibleSelecionarModelosReferenciasModal}
                      onHide={() => setVisibleSelecionarModelosReferenciasModal(false)}
                      avaliacao={form.values}
                    />
                  </SearchClearInput>
                </div>
                
                <div className='field md:col-11'>
                  <SearchClearInput
                    label="Modelo Fipe"
                    field="desfip_ava"
                    value={form.values.desfip_ava}
                    onClear={onClearModeloFipe}
                    onSearch={() => abrirModalModelosFipe()}
                    placeholder="Clique na lupa para pesquisar"
                    disabled={!edicaoHabilitada}
                    disabledAutoComplete={true}
                    className="inputfield w-full"
                  >
                    <SelecionarModelosFipeModal
                      visible={visibleSelecionarModelosFipeModal}
                      onHide={() => setVisibleSelecionarModelosFipeModal(false)}
                      avaliacao={form.values}
                      onModeloConfirmado={(avaliacao) => handleModeloConfirmado(avaliacao)}
                    />
                  </SearchClearInput>
                </div>
              </div>

            </div>
            <div className={(CommonHelper.isDesktop() ? 'w-50' : '')}>
              <h1 className={(CommonHelper.isDesktop() ? 'my-4 px-2' : 'my-4 px-2')}>Definição de preços</h1>

              <div className={(CommonHelper.isDesktop() ? '' : 'margin-y') + 'field md:col-18 sombra-caixa'}>
                <div>

                  <div className={(CommonHelper.isDesktop() ? 'flex' : 'grid')}>
                    <div className={(CommonHelper.isDesktop() ? 'field md:col-6' : 'md:col-4 field col-12 px-3')}>

                      <TextInput
                        label="Valor Fipe"
                        placeholder="Valor Fipe"
                        disabled={!edicaoHabilitada}
                        value={formatToCurrency(form.values.valfip_ava)}
                        name="valfip_ava"
                        onChange={handleChangeCurrency}
                        className="inputfield w-full"
                      />
                    </div>
                    <div className={(CommonHelper.isDesktop() ? 'field md:col-6' : 'md:col-4 field col-12 px-3')}>

                      <TextInput
                        label="Valor de manutenção"
                        placeholder="Valor de manutenção"
                        className="inputfield w-full"
                        disabled={!edicaoHabilitada}
                        value={formatToCurrency(form.values.totman_ava)}
                        name="totman_ava"
                        onChange={handleChangeCurrency}
                      />
                    </div>

                  </div>
                  <div className={(CommonHelper.isDesktop() ? 'flex' : 'grid')}>
                    <div className={(CommonHelper.isDesktop() ? 'field md:col-6' : 'md:col-4 field col-12 px-3')}>
                      <TextInput
                        label="Valor previsto de venda"
                        placeholder="Valor previsto de venda"
                        className="inputfield w-full"
                        disabled={!edicaoHabilitada}
                        value={formatToCurrency(form.values.preven_ava)}
                        name="preven_ava"
                        onChange={handleChangeCurrency}
                      />
                    </div>
                    <div className={(CommonHelper.isDesktop() ? 'field md:col-6' : 'md:col-4 field col-12 px-3 ')}>

                      <TextInput
                        label="Valor avaliado"
                        placeholder="Valor avaliado"
                        disabled={!edicaoHabilitada}
                        value={formatToCurrency(form.values.valor_ava)}
                        name="valor_ava"
                        onChange={handleChangeCurrency}
                        className="inputfield w-full"
                      />
                    </div>
                  </div>
                </div>

              </div>

            </div>

          </div>

        </div>
      </div>
      <div className="form-divider">
      </div>
      <div className="field col-12 md:col-12">
        <TextAreaInput
          label="Observação"
          placeholder="Observação"
          className="inputfield w-full"
          value={form.values.observ_ava}
          name="observ_ava"
          onChange={form.handleChange}
          disabled={!edicaoHabilitada}
        />
      </div>
    </div>)
}

export default DadosPrincipaisAva
