import List from '../../../classes/List'
import { showErrorMessage, showWarnMessage } from '../../../components/utils/Message'
import { GerenciamentoEstoqueVeiculoModel } from '../../../models/vendas/negociacao/GerenciamentoEstoqueVeiculoModel'
import { decrementActiveLoadingRequests, incrementActiveLoadingRequests, startLoading, stopLoading } from '../../../redux/actions'
import { dispatch } from '../../../redux/store'
import DocumentosService from '../../../services/cadastro/documento/DocumentosService'
import VeiculoService from '../../../services/cadastro/veiculo/VeiculoService'
import ConfigService from '../../../services/configuracao/ConfigService'
import GerenciamentoEstoqueVeiculoService from '../../../services/vendas/negociacao/GerenciamentoEstoqueVeiculoService'
import { persistenciaVendaVeiculo } from './PersistenciaVenderVeiculo'

class VenderVeiculo extends List {
  constructor (props) {
    super(props, GerenciamentoEstoqueVeiculoModel, GerenciamentoEstoqueVeiculoService, '', 'codpro_pre', 25)
  }

    handleVenderVeiculo = async (dadosVeiculo) => {
      try {
        const veiculosValidosParaVenda = []
        const veiculoService = new VeiculoService()
        localStorage.setItem('empresaOferta', dadosVeiculo.ofeemp_vei)

        dispatch(incrementActiveLoadingRequests())
        dispatch(startLoading())
        if (dadosVeiculo.itemConjuntoVeiculo && dadosVeiculo.itemConjuntoVeiculo.conjuntoVeiculo.ativo_cjv) {
          const itensConjunto = dadosVeiculo.itemConjuntoVeiculo.conjuntoVeiculo.itensConjunto

          for (const itemConjunto of itensConjunto) {
            const veiculoConjunto = await veiculoService.get(itemConjunto.codvei_icj)

            if (await persistenciaVendaVeiculo.persistenciaParaVenderVeiculo(veiculoConjunto)) {
              veiculosValidosParaVenda.push(veiculoConjunto)
            }
          }

          if (itensConjunto.length !== veiculosValidosParaVenda.length) {
            dispatch(decrementActiveLoadingRequests())
            dispatch(stopLoading())
            return
          }
        } else {
          if (await persistenciaVendaVeiculo.persistenciaParaVenderVeiculo(dadosVeiculo)) {
            const veiculo = await veiculoService.get(dadosVeiculo.codigo_vei)
            veiculosValidosParaVenda.push(veiculo)
          }
        }

        if (veiculosValidosParaVenda.length === 0) {
          dispatch(decrementActiveLoadingRequests())
          dispatch(stopLoading())
          return
        }

        let negociacao
        let config3326 = await ConfigService.getValor(3326, false)
        let config3325 = await ConfigService.getValor(3325, false)
        let config3334 = await ConfigService.getValor(3334)
        let documentoRoayltes = []
        if (config3325) {
          documentoRoayltes = await DocumentosService.getTipoDocumento(Number(config3326))
          if (!config3326) {
            dispatch(decrementActiveLoadingRequests())
            dispatch(stopLoading())
            showWarnMessage('Informe o código do tipo de documento referente ao pagamento de royalties na venda de veículos. Verifique a configuração "3326".', 10000)
            return false
          }
          if (!documentoRoayltes) {
            dispatch(decrementActiveLoadingRequests())
            dispatch(stopLoading())
            showWarnMessage("Não foi informado um código válido para o tipo de documento referente ao pagamento de royalties na venda de veículos. Verifique a configuração '3326'.", 10000)
            return false
          }
          if (!documentoRoayltes.pessoaPagamentoTitulo) {
            dispatch(decrementActiveLoadingRequests())
            dispatch(stopLoading())
            showWarnMessage('Informe o destino (pagar) no cadastro do tipo de documento.')
            return false
          }
          if (!documentoRoayltes.pessoaRecebimentoTitulo) {
            dispatch(decrementActiveLoadingRequests())
            dispatch(stopLoading())
            showWarnMessage('Informe o destino (receber) no cadastro do tipo de documento.')
            return false
          }
          if (config3334 <= 0) {
            dispatch(decrementActiveLoadingRequests())
            dispatch(stopLoading())
            showWarnMessage('Informe o valor dos royalties pagos na venda de veículos (Configuração 3334)')
            return false
          }
        }
        if (veiculosValidosParaVenda.length > 1) {
          negociacao = {
            conjuntoVeiculos: veiculosValidosParaVenda,
            operacao: 'Venda',
            documentoAutomatico: documentoRoayltes,
            valorDocumentoAutomatico: Number(config3334)
          }
        } else {
          negociacao = {
            veiculoVenda: veiculosValidosParaVenda[0],
            operacao: 'Venda',
            documentoAutomatico: documentoRoayltes,
            valorDocumentoAutomatico: Number(config3334)

          }
        }

        dispatch(decrementActiveLoadingRequests())
        dispatch(stopLoading())

        localStorage.setItem('negociacao', JSON.stringify(negociacao))
        return true
      } catch (error) {
        console.error(error)
        dispatch(stopLoading())
        dispatch(decrementActiveLoadingRequests())
        showErrorMessage(error.message || 'Ocorreu um erro inesperado!')
      }
    }
}

export const venderVeiculo = new VenderVeiculo()
