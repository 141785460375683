import { InputText } from 'primereact/inputtext'
import { ScrollTop } from 'primereact/scrolltop'
import { classNames } from 'primereact/utils'
import React from 'react'
import List from '../../../../classes/List'
import AppButton from '../../../../components/layout/AppButton'
import Container from '../../../../components/layout/Container'
import DataList from '../../../../components/layout/DataList'
import Page from '../../../../components/layout/Page'
import Confirm from '../../../../components/utils/Confirm'
import { showErrorMessage, showSuccessMessage, showWarnMessage } from '../../../../components/utils/Message'
import Modal from '../../../../components/utils/Modal'
import PreferencesButton from '../../../../components/utils/PreferencesButton'
import TablePreferences from '../../../../components/utils/TablePreferences'
import ReservaVeiculoModal from '../../../../forms/vendas/negociacao/gerenciamentoestoque/ReservaVeiculoModal'
import AES from '../../../../helpers/AES'
import CommonHelper from '../../../../helpers/CommonHelper'
import { formatCurrencyToNumber, formatPlacha, formatToCurrency } from '../../../../helpers/formaters'
import { createColumns, getColunasHabilitadas } from '../../../../helpers/tableConfigs'
import { GerenciamentoEstoqueVeiculoModel } from '../../../../models/vendas/negociacao/GerenciamentoEstoqueVeiculoModel'
import { getStore } from '../../../../redux/store'
import VeiculoService from '../../../../services/cadastro/veiculo/VeiculoService'
import ConfigService from '../../../../services/configuracao/ConfigService'
import GerenciamentoEstoqueVeiculoService from '../../../../services/vendas/negociacao/GerenciamentoEstoqueVeiculoService'

import BloquearVeiculoModal from '../../../../forms/vendas/negociacao/gerenciamentoestoque/BloquearVeiculoModal'
import PermissaoService from '../../../../services/cadastro/pessoa/PermissaoService'
import { venderVeiculo } from '../../persistencia/VenderVeiculo'

export class GerenciamentoEstoqueVeiculos extends List {
  constructor (props) {
    super(props, GerenciamentoEstoqueVeiculoModel, GerenciamentoEstoqueVeiculoService, '', 'codpro_pre', 25)
    this.state = {
      ...this.state,
      expandedRows: null,
      reserva: null,
      visualizarReserva: false,
      visibleConfirmacao: false,
      visiblePreferencesModal: false,
      visibleAcessoriosModal: false,
      acessoriosList: '',
      config1099: ConfigService.getValor(1099) || false,
      permissaoParaSimularVenda: PermissaoService.getByCodigo('195') || true,
      permissaoParaBloquear: false,
      dadosBloqueio: {},
      config1575: ConfigService.getValor(1575) || false,
      config3252: ConfigService.getValor(3252) || false,
      permissaoPrecificar: PermissaoService.getByCodigo('60') || false
    }

    var arrColunas = [
      { field: 'ofeemp_vei', header: 'Emp', width: '5%' },
      { field: 'codtiv_vei', header: 'N/U', width: '6%', customClassName: this.getTipoVeiculoClassName, customBody: this.getTipoVeiculo },
      { field: 'consig_nve', header: 'Próprio/\nConsignado', width: '8.5%', customBody: this.getTipoVeiculoProprioConsignado },
      { field: 'infoConjunto', header: 'Conjunto', width: '15%', customBody: this.getConjunto, enabled: false },
      { field: 'placa_vei', header: 'Placa/Chassi', width: '15%', customBody: this.getPlacha },
      { field: 'descri_mar', header: 'Marca/Modelo', width: '10%', customBody: this.getMarcaModelo },
      { field: 'descri_cor', header: 'Cor', width: '10%' },
      { field: 'anofab_vei', header: 'Ano', width: '7.5%', customBody: this.getAnoFabAnoMod },
      { field: 'preco_pre', header: 'Preço', width: '10%', customBody: this.getPreco },
      { field: 'prepro_pre', header: 'Preço Promocional', width: '10%', customBody: this.getPrecoPromocional, enabled: false },
      { field: 'km_vei', header: 'KM', width: '7.5%', customBody: this.getKilometers, enabled: false },
      { field: 'dias_est', header: 'Dias', width: '5%' },
      { field: 'descri_vdi', header: 'Disponibilidade', width: '10%', customClassName: this.getDescricaoDisponibilidadeVeiculoClassName, customBody: this.getDescricaoDisponibilidadeVeiculo, enabled: false },
      { field: 'descri_ves', header: 'Situação', width: '8%', customClassName: this.getDescricaoSituacaoVeiculoClassName, enabled: false },
      { field: 'isIntegradoAG', header: 'Integrado com o Auto Gestor?', width: '8%', enabled: false }
    ]
    this.setColumns(createColumns(arrColunas))
  }

  async componentDidMount () {
    const permissaoParaSimularVenda = await PermissaoService.getByCodigo('195')
    const permissaoPrecificar = await PermissaoService.getByCodigo('60')
    const permissaoParaBloquear = await PermissaoService.getByCodigo('28')
    const config1575 = await ConfigService.getValor(1575)
    const config3252 = await ConfigService.getValor(3252)
    this.setState({ permissaoParaSimularVenda, permissaoParaBloquear, config1575, config3252, permissaoPrecificar })
    localStorage.removeItem('empresaOferta')

    await super.componentDidMount()
  }

  getTipoVeiculo (data) {
    return data.codtiv_vei === 'n' ? 'Novo' : 'Usado'
  }

  getTipoVeiculoClassName (data) {
    return `tipo-veiculo-${data.codtiv_vei}`
  }

  getTipoVeiculoProprioConsignado (data) {
    return data.consig_nve ? 'Consignado' : 'Próprio'
  }

  getConjunto (data) {
    return data.infoConjunto
  }

  getPlacha (data) {
    return formatPlacha(data.placa_vei, data.chassi_vei)
  }

  getMarcaModelo (data) {
    return ` ${data.descri_mar}  /  ${data.descri_mod} `
  }

  getAnoFabAnoMod (data) {
    return data.anofab_vei + '/' + data.anomod_vei
  }

  getPreco (data) {
    return formatToCurrency(data.preco_pre, true)
  }

  getPrecoPromocional (data) {
    return formatToCurrency(data.prepro_pre, true)
  }

  getKilometers (data) {
    return data.km_vei ? data.km_vei.toLocaleString('pt-BR') : ''
  }

  getDescricaoDisponibilidadeVeiculo (data) {
    return data.codigo_vdi === 'D' ? 'Disponível' : data.descri_vdi
  }

  getDescricaoDisponibilidadeVeiculoClassName (data) {
    return `veiculo-disponibilidade-${data.codigo_vdi}`
  }

  getDescricaoSituacaoVeiculoClassName (data) {
    return `veiculo-situacao-perven-${data.perven_ves ? '1' : '0'}`
  }

  handleComprarVeiculo = async () => {
    try {
      const negociacao = {
        veiculoVenda: { negociacaoVeiculo: {} },
        operacao: 'Compra'
      }

      localStorage.setItem('negociacao', JSON.stringify(negociacao))

      this.props.history.push('/negociacao')
    } catch (error) {
      showErrorMessage(error.message || 'Ocorreu um erro inesperado!')
    }
  }

  handleConsignarVeiculo = async () => {
    const negociacao = {
      veiculoVenda: { negociacaoVeiculo: {} },
      operacao: 'Consignação'
    }

    localStorage.setItem('negociacao', JSON.stringify(negociacao))

    try {
      this.props.history.push('/negociacao')
    } catch (error) {
      showErrorMessage(error.message || 'Ocorreu um erro inesperado!')
    }
  }

  handleReserva = async (reservaForm) => {
    try {
      const { codigo_pre, vendedor, pessoa, motivoReserva, clienteAutogestor, removerAnuncios } = reservaForm.values
      await VeiculoService.reservar(codigo_pre, vendedor.codigo_ven, pessoa.codigo_pes, motivoReserva)
      const { list } = this.state

      const lista = list.map(row => {
        if (row.codigo_pre === Number(codigo_pre)) {
          return { ...row, codigo_vdi: 'R', descri_vdi: 'Reservado' }
        } else {
          return row
        }
      })

      this.setState({ list: lista })
      this.onFilter()
      showSuccessMessage('Reserva efetuada com sucesso!')
      await VeiculoService.reservarAutoGestor(codigo_pre, vendedor.codigo_ven, clienteAutogestor, removerAnuncios)
    } catch (error) {
      showErrorMessage(error.message || 'Ocorreu um erro inesperado ao tentar reservar o veículo!')
    }
  }

  handleCancelarReserva = async (codigo_pre, codigoVen) => {
    try {
      await VeiculoService.cancelarReserva(codigo_pre)

      const { list } = this.state
      const lista = list.map(row => {
        if (row.codigo_pre === codigo_pre) {
          return { ...row, codigo_vdi: 'D', descri_vdi: 'Disponível para venda' }
        } else {
          return row
        }
      })

      this.setState({ list: lista })

      showSuccessMessage('Reserva cancelada com sucesso!')
      await VeiculoService.cancelarReservaAutoGestor(codigo_pre, codigoVen)
    } catch (error) {
      showErrorMessage(error.message || 'Ocorreu um erro inesperado ao tentar cancelar a reserva!')
    }
  }

  showConfirmacaoModal = (visibleConfirmacao) => {
    if (visibleConfirmacao) {
      return (
        <Confirm
          visible={this.state.visibleConfirmacao}
          onConfirm={() => {
            this.handleCancelarReserva(this.state.selected.codigo_pre, this.state.selected.venres_vei)
            this.setState({ visibleConfirmacao: false })
          }}
          onCancel={() => this.setState({ visibleConfirmacao: false })}
          title="Reserva de veículos"
          description="Confirmar cancelar a reserva?"
        />
      )
    }
  }

  onSelectOption = (option) => {
    let { filter } = this.state
    this.setState({ opcaoSelecionada: option })

    if (option === 't') {
      filter.codigo_vdi = ''
      filter.codtiv_vei = ''
    }

    if (option === 'd') {
      filter.codigo_vdi = 'D'
      filter.codtiv_vei = ''
    }

    if (option === 'b') {
      filter.codigo_vdi = 'B,R'
      filter.codtiv_vei = ''
    }

    if (option === 'n') {
      filter.codigo_vdi = ''
      filter.codtiv_vei = 'n'
    }

    if (option === 'u') {
      filter.codigo_vdi = ''
      filter.codtiv_vei = 'u'
    }

    this.setState({ filter }, this.onFilter)
  }

  handleChangeCurrencyFilter = (e) => {
    const { filter } = this.state
    filter[e.target.name] = formatCurrencyToNumber(e.target.value)
    this.setState({ filter })
  }

  expandedFiltersTemplate = () => (
    <>
      <div className="field col-12 md:col-4">
        <label className="label">Ano</label>
        <div className="formgrid grid">
          <div className="col-6">
            <InputText placeholder="de" name="anoModeloInicial" value={this.state.filter.anoModeloInicial}
              onChange={this.handleChangeFilter} className="inputfield w-full" />
          </div>
          <div className="col-6">
            <InputText placeholder="até" name="anoModeloFinal" value={this.state.filter.anoModeloFinal}
              onChange={this.handleChangeFilter} className="inputfield w-full" />
          </div>
        </div>
      </div>
      <div className="field col-12 md:col-4">
        <label className="label">Preço</label>
        <div className="formgrid grid">
          <div className="col-6">
            <InputText placeholder="de" name="precoInicial" value={this.state.filter.precoInicial}
              onChange={this.handleChangeFilter} className="inputfield w-full" />
          </div>
          <div className="col-6">
            <InputText placeholder="até" name="precoFinal" value={this.state.filter.precoFinal}
              onChange={this.handleChangeFilter} className="inputfield w-full" />
          </div>
        </div>
      </div>
      <div className="field col-12 md:col-4">
        <label className="label">Quilometragem</label>
        <div className="formgrid grid">
          <div className="col-6">
            <InputText placeholder="de" name="kmInicial" value={this.state.filter.kmInicial}
              onChange={this.handleChangeFilter} className="inputfield w-full" />
          </div>
          <div className="col-6">
            <InputText placeholder="até" name="kmFinal" value={this.state.filter.kmFinal}
              onChange={this.handleChangeFilter} className="inputfield w-full" />
          </div>
        </div>
      </div>
      <div className="col-12 flex justify-content-end mb-4">
        <AppButton label="Buscar" icon="pi pi-search" onClick={this.onFilter} />
      </div>
    </>
  )

  render () {
    const { state, props } = this
    const columns = this.columns

    const rowExpansionTemplate = (data) => {
      const empresaLogada = getStore().empresaLogada
      return (
        <div className="row-expansion grid" style={{ textAlign: '' }}>
          <div className="grid col-12 md:col-4">
            <div className="col-12"><h6>Detalhes do veículo:</h6></div>
            <div className="col-12 flex">
              <span className="row-expansion-label">Situação:</span>
              <span className="row-expansion-value">{data.descri_ves}</span>
            </div>
            <div className="col-12 flex">
              <div className="row-expansion-label">Próprio ou consignado:</div>
              <div className={classNames({ 'consignado-color': !data.consig_nve }, 'row-expansion-value')}>
                {data.consig_nve ? 'Consignado' : 'Próprio'}
              </div>
            </div>
            <div className="col-12 flex">
              <div className="row-expansion-label">Repasse:</div>
              <div className="row-expansion-value">{data.repass_vei ? 'Sim' : 'Não'}</div>
            </div>
            <div className="col-12 flex">
              <div className="row-expansion-label">Disponibilidade:</div>
              <div className="row-expansion-value">{data.descri_vdi}</div>
            </div>
            <div className="col-12 flex">
              <div className="row-expansion-label">Acessórios:</div>
              <div className="row-expansion-value underline cursor-pointer"
                onClick={() => this.setState({ visibleAcessoriosModal: true, acessoriosList: data.descri_ace })}>
                {data.sigla_ace}
              </div>
            </div>
            {
              this.state.config1575 && (
                <div className="col-12 flex">
                  <div className="row-expansion-label">Log intregração Auto Gestor:</div>
                  <div className="row-expansion-value">
                    {data.infrec_agl ? data.infrec_agl : data.isIntegradoAG === 'Sim' ? '' : this.state.config3252.includes('' + data.codigo_ves) ? `Veículo não integrado, porque a situação ${data.codigo_vei} - ${data.descri_ves} está configurada para não ser enviada ao autogestor. Configuração: 3252 ` : ''}
                  </div>
                </div>
              )}
          </div>
          <div className="grid col-12 md:col-8">
            <div className="row-expansion-buttons col-12 flex no-padding">
              <AppButton
                label="Link do veículo"
                className="row-expansion-button"
                onClick={() => {
                  const encryptedDatabase = AES.encryptWithAES(getStore().usuarioLogado.databaseConnectionName)
                  window.open(
                    `${window.location.origin}/landing-veiculo/` +
                    `${CommonHelper.formatStringToURL(encryptedDatabase)}/${data.codigo_vei}/${data.ofeemp_vei}`
                  )
                }}
              />
              <AppButton
                label="Visualizar cadastro"
                className="ml-1 expansion-button"
                onClick={() => { this.props.history.push(`/veiculo/${data.codigo_vei}`) }}
              />
              {((data.codigo_vdi === 'B' || (data.codigo_vdi === 'D' && state.permissaoParaBloquear)) && data.perblo_ves === 1) && (
                <AppButton label={data.codigo_vdi === 'B' ? 'Ver bloqueio' : 'Bloquear'} className="ml-1 row-expansion-button" onClick={async () => {
                  try {
                    const dadosBloqueio = await VeiculoService.getDadosBloqueio(data.codigo_vei)
                    this.setState({ dadosBloqueio, visibleBloquearModal: true })
                  } catch (error) {
                    showErrorMessage(error.message || 'Ocorreu um erro inesperado ao buscar os dados do bloqueio do veículo!')
                  }
                }} />
              )}
              {data.codigo_vdi === 'D' && (
                <AppButton label="Reservar" className="ml-1 row-expansion-button" onClick={async () => {
                  try {
                    if (empresaLogada.codigo_emp !== data.ofeemp_vei) {
                      showWarnMessage(`Veículo em estoque na empresa ${data.ofeemp_vei}!`)
                      return
                    }

                    const reserva = await VeiculoService.getDadosReserva(data.codigo_pre)
                    this.setState({ reserva: reserva, isVisibleReservaModal: true, visualizarReserva: false })
                  } catch (error) {
                    showErrorMessage(error.message || 'Ocorreu um erro inesperado ao buscar os dados da reserva do veículo!')
                  }
                }} />
              )}
              {(data.codigo_vdi !== 'B' && data.codigo_vdi !== 'V' && data.codigo_vdi !== 'I' && (data.perven_ves === 1)) && (
                <AppButton
                  label="Vender"
                  className="ml-1 row-expansion-button"
                  onClick={async () => {
                    if (await venderVeiculo.handleVenderVeiculo(data)) {
                      this.props.history.push('/negociacao')
                    }
                  }}
                />
              )}
              {data.codigo_vdi === 'R' && (
                <AppButton label="Ver reserva" className="ml-1 row-expansion-button" onClick={async () => {
                  try {
                    const reserva = await VeiculoService.getDadosReserva(data.codigo_pre)
                    this.setState({ reserva: reserva, isVisibleReservaModal: true, visualizarReserva: true })
                  } catch (error) {
                    showErrorMessage(error.message || 'Ocorreu um erro inesperado ao buscar os dados da reserva do veículo!')
                  }
                }} />
              )}
              {data.codigo_vdi === 'R' && (
                <AppButton label="Cancelar reserva" className="ml-1 row-expansion-button" onClick={() => {
                  if (empresaLogada.codigo_emp !== data.ofeemp_vei) {
                    showWarnMessage(`Veículo em estoque na empresa ${data.ofeemp_vei}!`)
                  }

                  this.setState({ visibleConfirmacao: true })
                }}
                disabled={!(data.codigo_vdi === 'R') || empresaLogada.codigo_emp !== data.ofeemp_vei} />
              )}

              <AppButton label="Precificar" className="ml-1 row-expansion-button" onClick={async () => {
                if (empresaLogada.codigo_emp !== data.ofeemp_vei) {
                  showWarnMessage(`Veículo em estoque na empresa ${data.ofeemp_vei}!`)
                  return
                }
                if (!state.permissaoPrecificar) {
                  showWarnMessage(`Usuário logado não possui permissão para alterar o preço do veículo: ${formatPlacha(data.placa_vei, data.chassi_vei)}!`)
                  return
                }
                props.history.push(`/precificar/${data.codigo_vei}`)
              }} />

              {state.permissaoParaSimularVenda && (
                <AppButton label="Simular venda" className="ml-1 row-expansion-button" onClick={async () => {
                  if (empresaLogada.codigo_emp !== data.ofeemp_vei) {
                    showWarnMessage(`Veículo em estoque na empresa ${data.ofeemp_vei}!`)
                    return
                  }
                  if (!state.permissaoParaSimularVenda) {
                    showWarnMessage(`Usuário logado não possui permissão para simular a venda do veículo: ${formatPlacha(data.placa_vei, data.chassi_vei)}!`)
                    return
                  }
                  props.history.push(`/simulacao-venda/${data.codigo_vei}`)
                }} />
              )}
            </div>
          </div>
        </div>
      )
    }

    return (
      <Page>
        {!CommonHelper.isDesktop() && (
          <ScrollTop threshold={1200} />
        )}
        <div className="flex justify-content-between align-items-center page-header">
          <h4>Estoque de veículos</h4>
          <div className="page-header-buttons">
            <AppButton
              label="Consignar veículo"
              icon="pi pi-plus"
              onClick={() => {
                this.handleConsignarVeiculo()
              }}/>
            <AppButton
              label="Comprar veículo"
              icon="pi pi-plus"
              className="ml-3"
              onClick={() => {
                this.handleComprarVeiculo()
              }}
            />
          </div>
        </div>
        <div className="table-options-container">
          <div className="table-options">
            <div className={classNames({ active: state.opcaoSelecionada === 't' }, 'filter-option')}
              onClick={() => this.onSelectOption('t')}>
              <span className="option-label">Todos</span>
              <div className="option-quantity">{state.meta?.quantidadeTotal}</div>
            </div>
            <div className={classNames({ active: state.opcaoSelecionada === 'd' }, 'filter-option')}
              onClick={() => this.onSelectOption('d')}>
              <span className="option-label">Disponíveis</span>
              <div className="option-quantity">{state.meta?.quantidadeDisponiveis}</div>
            </div>
            <div className={classNames({ active: state.opcaoSelecionada === 'b' }, 'filter-option')}
              onClick={() => this.onSelectOption('b')}>
              <span className="option-label">Bloqueados/Reservados</span>
              <div className="option-quantity">{state.meta?.quantidadeBloqueadosReservados}</div>
            </div>
            <div className={classNames({ active: state.opcaoSelecionada === 'n' }, 'filter-option')}
              onClick={() => this.onSelectOption('n')}>
              <span className="option-label">Novos</span>
              <div className="option-quantity">{state.meta?.quantidadeNovos}</div>
            </div>
            <div className={classNames({ active: state.opcaoSelecionada === 'u' }, 'filter-option')}
              onClick={() => this.onSelectOption('u')}>
              <span className="option-label">Usados</span>
              <div className="option-quantity">{state.meta?.quantidadeUsados}</div>
            </div>
          </div>
          <div className="table-preferences">
            <PreferencesButton onClick={() => this.setState({ visiblePreferencesModal: true })} />
          </div>
        </div>
        <Container>
          <DataList
            data={state.list}
            selected={state.selected}
            onSelect={e => this.onSelect(e)}
            onRowClick={(e) => this.handleRowExpansion(e.data)}
            rows={state.rows}
            totalRecords={state.totalRecords}
            first={state.first}
            onPage={this.onPageChange}
            expandedRows={state.expandedRows}
            rowExpansionTemplate={rowExpansionTemplate}
            page={state.page}
            showFilter={true}
            filterPlaceholder="Procurar veículos"
            filterName="descricaoFiltro"
            filterOnChange={this.handleChangeFilter}
            onFilter={this.onFilter}
            expandedFiltersTemplate={this.expandedFiltersTemplate}
            className='flex'
          >
            {getColunasHabilitadas(columns, 'gerenciamentoEstoque')}
          </DataList>
        </Container>
        {this.showConfirmacaoModal(state.visibleConfirmacao)}
        <BloquearVeiculoModal
          visible={state.visibleBloquearModal}
          handleReserva={this.handleReserva}
          hide={() => this.setState({ visibleBloquearModal: false, dadosBloqueio: {} })}
          motivo={state.dadosBloqueio.motivoBloqueio}
          descricaoVeiculo={state.dadosBloqueio.descricaoVeiculoCompleta}
          veiculo={state.selected}
          onFilter={this.onFilter}
          list={state.list}
          setList={(list) => this.setState({ list })}
        />
        <ReservaVeiculoModal
          visible={state.isVisibleReservaModal}
          reserva={state.reserva}
          visualizarReserva={state.visualizarReserva}
          hide={() => this.setState({ isVisibleReservaModal: false, visualizarReserva: false })}
        />
        <TablePreferences
          title="Preferências da tabela de gerenciamento de estoque"
          tableName="gerenciamentoEstoque"
          columns={columns}
          visible={state.visiblePreferencesModal}
          hide={() => this.setState({ visiblePreferencesModal: false })}
        />
        <Modal
          header="Lista de acessórios"
          width="40"
          visible={state.visibleAcessoriosModal}
          onHide={() => this.setState({ visibleAcessoriosModal: false, acessoriosList: '' })}
        >
          {state.acessoriosList?.split(', ').map((acessorio, idx) => (
            <div key={idx}>{acessorio}</div>
          ))}
        </Modal>
      </Page>
    )
  }
}

export default GerenciamentoEstoqueVeiculos
