import { Column } from 'primereact/column'
import { ColumnGroup } from 'primereact/columngroup'
import { ProgressSpinner } from 'primereact/progressspinner'
import { Row } from 'primereact/row'
import { ScrollTop } from 'primereact/scrolltop'
import React from 'react'
import { ReactComponent as CarroBloqueadoAtivo } from '../../../../assets/icones-analise-estoque/carro-bloqueado-ativado.svg'
import { ReactComponent as CarroPatioAtivo } from '../../../../assets/icones-analise-estoque/carro-patio-ativado.svg'
import { ReactComponent as CarroReservadoAtivo } from '../../../../assets/icones-analise-estoque/carro-reservado-ativado.svg'
import { ReactComponent as EmDemonstracaoAtivo } from '../../../../assets/icones-analise-estoque/em-demonstracao-ativado.svg'
import { ReactComponent as KmAltaAtivo } from '../../../../assets/icones-analise-estoque/km-alta-ativado.svg'
import { ReactComponent as OrdemServico } from '../../../../assets/icones-analise-estoque/ordem-servico.svg'
import { ReactComponent as SetaBaixo } from '../../../../assets/icones-analise-estoque/seta-baixo.svg'
import { ReactComponent as SetaCima } from '../../../../assets/icones-analise-estoque/seta-cima.svg'
import List from '../../../../classes/List'
import AppButton from '../../../../components/layout/AppButton'
import Container from '../../../../components/layout/Container'
import DataList from '../../../../components/layout/DataList'
import Page from '../../../../components/layout/Page'
import { showSuccessMessage } from '../../../../components/utils/Message'
import VeiculoModal from '../../../../forms/cadastro/veiculo/veiculo/VeiculoModal'
import SelecionarModelosFipeModal from '../../../../forms/vendas/negociacao/avaliacao/SelecionarModeloFipeModal'
import SelecionarModelosReferenciaModal from '../../../../forms/vendas/negociacao/avaliacao/SelecionarModelosReferenciaModal'
import CommonHelper from '../../../../helpers/CommonHelper'
import { formatCurrencyToNumber, formatToCurrency } from '../../../../helpers/formaters'
import { createColumns, getColunasHabilitadas } from '../../../../helpers/tableConfigs'
import ProdutoEmpresaService from '../../../../services/cadastro/produto/ProdutoEmpresaService'
import VeiculoService from '../../../../services/cadastro/veiculo/VeiculoService'
import AnaliseEstoqueService from '../../../../services/vendas/negociacao/AnaliseEstoqueService'
import ModalSimulacaoVenda from '../simulacaovenda/ModalSimulacaoVenda'
import ModalAnaliseEstoqueAlertas from './ModalAnaliseEstoqueAlertas'

export class AnaliseEstoqueVeiculos extends List {
  constructor (props) {
    super(props, AnaliseEstoqueVeiculos, AnaliseEstoqueService, '', 'codpro_pre', 25)
    this.state = {
      ...this.state,
      expandedRows: null,
      reserva: null,
      visualizarReserva: false,
      visibleConfirmacao: false,
      visiblePreferencesModal: false,
      visibleAcessoriosModal: false,
      visibleModalAlertas: false,
      visibleVeiculoModal: false,
      visibleSelecionarModelosReferenciasModal: false,
      visibleSelecionarModelosFipeModal: false,
      visibleModalSimulacaoVenda: false
    }

    var arrColunas = [
      { field: 'descricao_vei', header: 'VEÍCULO', width: '25%' },
      { field: 'porcentagemVendaxFipe', header: 'FIPE', borderLeft: '2px solid #E8E9EB', customBody: this.formatePorcentagemVendaxFipe },
      { field: 'porcentagemCompraMercado', header: 'COMPRA \nMERCADO', customBody: this.formatePorcentagemCompraMercado },
      { field: 'porcentagemMenorVendaWebMotors', header: 'MENOR VENDA \nWEBMOTOR', customBody: this.formatePorcentagemMenorVendaWebMotors },
      { field: 'porcentagemCompra', header: 'COMPRA', customBody: this.formatePorcentagemCompra },
      { field: 'porcentagemCompraMaisCustos', header: 'COMPRA +\nCUSTOS', borderRight: '2px solid #E8E9EB', customBody: this.formatePorcentagemCompraMaisCustos },
      { field: 'porcentagemCompraxFipe', header: 'FIPE', customBody: this.formatePorcentagemCompraxFipe },
      { field: 'porcentagemCompraMercadoCompra', header: 'COMPRA \nMERCADO', customBody: this.formatePorcentagemCompraMercadoCompra },
      { field: 'porcentagemMenorVendaWebMotorsCompra', header: 'MENOR VENDA \nWEBMOTOR', customBody: this.formatePorcentagemCompraMercadoCompra },
      { field: 'prepro_pre', header: 'ALERTAS', borderLeft: '2px solid #E8E9EB', customBody: this.mostraAlertas, customClassName: this.classNameAlert.bind(this) }
    ]
    // customBody: this.getAnoFabAnoMod,
    this.setColumns(createColumns(arrColunas))
  }

  async componentDidMount () {
    localStorage.removeItem('empresaOferta')

    await super.componentDidMount()
  }

  classNameAlert (data) {
    return 'flex flex-wrap w-full bg-red-700'
  }

  formatePorcentagemVendaxFipe (data) {
    if (data.atufipe_vei) {
      return <ProgressSpinner style={{ width: '20px', height: '20px' }} strokeWidth="8" fill="var(--surface-ground)" />
    }
    if (data.porcentagemVendaxFipe >= 0) {
      return <div className='porcentagem-positiva'> {data.porcentagemVendaxFipe > 1 && <SetaCima color='#20A144'/> }  {Math.abs(data.porcentagemVendaxFipe) + '%'} </div>
    } else {
      return <div className='porcentagem-negativa'> <SetaBaixo color='#F05C54'/> {Math.abs(data.porcentagemVendaxFipe) + '%'} </div>
    }
  }

  formatePorcentagemCompraMercado (data) {
    if (data.atucommer_vei) {
      return <ProgressSpinner style={{ width: '20px', height: '20px', color: 'red' }} strokeWidth="8" />
    }
    if (data.porcentagemCompraMercado >= 0) {
      return <div className='porcentagem-positiva'> {data.porcentagemCompraMercado > 1 && <SetaCima color='#20A144'/> }  {Math.abs(data.porcentagemCompraMercado) + '%'} </div>
    } else {
      return <div className='porcentagem-negativa'> <SetaBaixo color='#F05C54'/> {Math.abs(data.porcentagemCompraMercado) + '%'} </div>
    }
  }

  formatePorcentagemMenorVendaWebMotors (data) {
    if (data.atumenvenweb_vei) {
      return <ProgressSpinner style={{ width: '20px', height: '20px', color: 'red' }} strokeWidth="8" />
    }
    if (data.porcentagemMenorVendaWebMotors >= 0) {
      return <div className='porcentagem-positiva'> {data.porcentagemMenorVendaWebMotors > 1 && <SetaCima color='#20A144'/> }  {Math.abs(data.porcentagemMenorVendaWebMotors) + '%'} </div>
    } else {
      return <div className='porcentagem-negativa'> <SetaBaixo color='#F05C54'/> {Math.abs(data.porcentagemMenorVendaWebMotors) + '%'} </div>
    }
  }

  formatePorcentagemCompra (data) {
    if (data.porcentagemCompra >= 0) {
      return <div className='porcentagem-positiva'> {data.porcentagemCompra > 1 && <SetaCima color='#20A144'/> } {Math.abs(data.porcentagemCompra) + '%'} </div>
    } else {
      return <div className='porcentagem-negativa'> <SetaBaixo color='#F05C54'/> {Math.abs(data.porcentagemCompra) + '%'} </div>
    }
  }

  formatePorcentagemCompraMaisCustos (data) {
    if (data.porcentagemCompraMaisCustos >= 0) {
      return <div className='porcentagem-positiva'> { data.porcentagemCompraMaisCustos > 1 && <SetaCima color='#20A144'/> }{Math.abs(data.porcentagemCompraMaisCustos) + '%'} </div>
    } else {
      return <div className='porcentagem-negativa'> <SetaBaixo color='#F05C54'/> {Math.abs(data.porcentagemCompraMaisCustos) + '%'} </div>
    }
  }

  formatePorcentagemCompraxFipe (data) {
    if (data.atufipe_vei) {
      return <ProgressSpinner style={{ width: '20px', height: '20px', color: 'red' }} strokeWidth="8" />
    }
    if (data.porcentagemCompraxFipe >= 0) {
      return <div className='porcentagem-negativa'> { data.porcentagemCompraxFipe > 1 && <SetaCima color='#F05C54'/> } {Math.abs(data.porcentagemCompraxFipe) + '%'} </div>
    } else {
      return <div className='porcentagem-positiva'> <SetaBaixo color='#20A144'/> {Math.abs(data.porcentagemCompraxFipe) + '%'} </div>
    }
  }

  formatePorcentagemCompraMercadoCompra (data) {
    if (data.atucommer_vei) {
      return <ProgressSpinner style={{ width: '20px', height: '20px', color: 'red' }} strokeWidth="8" />
    }
    if (data.porcentagemCompraMercadoCompra >= 0) {
      return <div className='porcentagem-negativa'> { data.porcentagemCompraMercadoCompra > 1 && <SetaCima color='#F05C54'/> } {Math.abs(data.porcentagemCompraMercadoCompra) + '%'} </div>
    } else {
      return <div className='porcentagem-positiva'> <SetaBaixo color='#20A144'/> {Math.abs(data.porcentagemCompraMercadoCompra) + '%'} </div>
    }
  }

  formatePorcentagemMenorVendaWebMotorsCompra (data) {
    if (data.atumenvenweb_vei) {
      return <ProgressSpinner style={{ width: '20px', height: '20px', color: 'red' }} strokeWidth="8" />
    }
    if (data.porcentagemMenorVendaWebMotorsCompra >= 0) {
      return <div className='porcentagem-negativa'> { data.porcentagemMenorVendaWebMotorsCompra > 1 && <SetaCima color='#F05C54'/> } {Math.abs(data.porcentagemMenorVendaWebMotorsCompra) + '%'} </div>
    } else {
      return <div className='porcentagem-positiva'> <SetaBaixo color='#20A144'/> {Math.abs(data.porcentagemMenorVendaWebMotorsCompra) + '%'} </div>
    }
  }

  mostraAlertas (data) {
    return <div className='w-full'>
      <div className='flex w-full  align-items-center flex-wrap'>
        {data.alertaDiasEstoque &&
        <p className='m-1'>
          <CarroPatioAtivo/>
        </p>
        }
        {data.alertaOSAberta &&
        <p className='m-1'>
          <OrdemServico color='#EC6A00'/>
        </p>
        }
        {data.alertaVeiculoBloqueado &&
        <p className='m-1'>
          <CarroBloqueadoAtivo/>
        </p>}
        {data.alertaVeiculoReservado &&
        <p className='m-1'>
          <CarroReservadoAtivo/>
        </p>}
        {data.alertaVeiculoDemonstracao &&
        <p className='m-1'>
          <EmDemonstracaoAtivo/>
        </p>}
        {data.alertaKMAlta &&
        <p className='m-1'>
          < KmAltaAtivo/>
        </p>}
      </div>

    </div>
  }

  getTipoVeiculoClassName (data) {
    return `tipo-veiculo-${data.porcentagemVendaxFipe}`
  }

  borderEsquerda () {
    return 'bordaEsqueda'
  }

  handleChangeCurrencyFilter = (e) => {
    const { filter } = this.state
    filter[e.target.name] = formatCurrencyToNumber(e.target.value)
    this.setState({ filter })
  }

  alterarCorDiasPatio = (dias) => {
    switch (dias) {
    case dias < 30:
      return '#20A144'
    case dias > 30 || dias < 90:
      return 'orange'
    default:
      return '#F05C54'
    }
  }

  alterarCorValoresVenda = (porcentagem) => {
    if (porcentagem > 0) {
      return '#20A144'
    }
    return '#F05C54'
  }

  alterarCorValoresCompra = (porcentagem) => {
    if (porcentagem <= 0) {
      return '#20A144'
    }
    return '#F05C54'
  }

  retornaSpanValoresPorcentagemVenda = (porcentagem, valor, label, tipo) => {
    let acimaAbaixo = 'abaixo'
    if (porcentagem > 0) {
      acimaAbaixo = 'acima'
    }
    return <span className="row-expansion-label">{tipo} <span style={{ color: this.alterarCorValoresVenda(porcentagem) }} >{porcentagem + '%'}</span> {acimaAbaixo + ' ' + label} {porcentagem > 0 && '('}<span style={{ color: this.alterarCorValoresVenda(porcentagem) }} >{formatToCurrency(valor)} </span>{porcentagem > 0 && ')'}</span>
  }

  retornaSpanValoresPorcentagemCompra = (porcentagem, valor, label, tipo) => {
    let acimaAbaixo = 'acima'
    if (porcentagem <= 0) {
      acimaAbaixo = 'abaixo'
    }
    return <span className="row-expansion-label">{tipo} <span style={{ color: this.alterarCorValoresCompra(porcentagem) }} >{Math.abs(porcentagem) + '%'}</span> {acimaAbaixo + ' ' + label} {porcentagem > 0 && '('}<span style={{ color: this.alterarCorValoresCompra(porcentagem) }} >{formatToCurrency(Math.abs(valor))} </span>{porcentagem > 0 && ')'}</span>
  }

    onSaveModalVeiculo = async (veiculo) => {
      this.getAll()
    }

    onSaveVeiculoIntegracao = async (veiculo) => {
      await VeiculoService.saveVeiculo(veiculo)
      this.getAll()
    }

    salvarPrecoVeiculoSimulacaoVenda = async (veiculo) => {
      await ProdutoEmpresaService.alterarPrecoVeiculo(veiculo.codigo_vei, { preco_pre: veiculo.preco_pre })
      this.setState({ visibleModalSimulacaoVenda: false })
      this.getAll()
    }

    render () {
      const { state } = this
      const columns = this.columns

      const rowExpansionTemplate = (data) => {
        return (
          <div className="row-expansion grid" style={{ textAlign: 'left' }}>
            <div className="grid md:col-2">

              <div className="col-14 pt-5 ">
                <div className="col-12 flex">
                </div>
                <div className="flex">
                  <span className="row-expansion-label">Patio:
                    <span style={{ color: this.alterarCorDiasPatio(data.dias_est) }} className={'row-expansion-value'}>{' ' + data.dias_est} {data.dias_est > 1 ? 'dias' : 'dia'}</span></span>
                </div>
                <div className="flex">
                  <span className="row-expansion-label">Dados atualizados em:</span>
                  <span className={'row-expansion-value'}>
                    {data.dadosAtualizadoEm}
                  </span>
                </div>
                <div className="flex">
                  <span className="row-expansion-label">Situação:
                    <span className="row-expansion-value">{' ' + data.descricaoDisponibilidadeSituacao}</span></span>
                </div>
              </div>
            </div>

            {/* Informações venda */}
            <div className="grid  md:col-10">
              <div className="grid col-12 md:col-7">
                <div className="col-12">
                  <div className="col-12 flex">
                    <div className="col-11">  {/* Inicio bloco  */}
                      <div className="col-12 flex">
                        <div className="col-6">
                          <div className='text-center'>
                            <span className="row-expansion-label">Venda: </span>
                            <span className="row-expansion-value">{formatToCurrency(data.preco_pre)}</span>
                          </div>
                          <div className='text-center'>
                            <span className="row-expansion-label">FIPE:</span>
                            <span className={'row-expansion-value'}>
                              {formatToCurrency(data.valfip_vei)}
                            </span>
                          </div>
                        </div>
                        <div className="col-10">
                          <div className='text-center'>
                            <span className="row-expansion-label">Menor venda WebMotors: </span>
                            <span className="row-expansion-value">{formatToCurrency(data.menvenweb_vei)}</span>
                          </div>
                          <div className='text-center'>
                            <span className="row-expansion-label">Compra mercado:</span>
                            <span className="row-expansion-value">{formatToCurrency(data.commer_vei)}</span>
                          </div>
                        </div>
                      </div>
                      <div className="col-12 flex flex-column justify-content-center align-items-center">
                        <div className='text-center'>
                          {this.retornaSpanValoresPorcentagemVenda(data.porcentagemVendaxFipe, data.fipeMenosVenda, 'da FIPE.', 'Venda')}

                        </div>
                        <div className='text-center'>
                          {this.retornaSpanValoresPorcentagemVenda(data.porcentagemCompraMercado, data.compraMercadoMenosVenda, 'da compra do mercado.', 'Venda')}
                        </div>
                        <div className='text-center'>
                          {this.retornaSpanValoresPorcentagemVenda(data.porcentagemMenorVendaWebMotors, data.vendaMenosCompraWebMotos, 'da menor venda na WebMotors.', 'Venda')}
                        </div>
                        <div className='text-center'>
                          {this.retornaSpanValoresPorcentagemVenda(data.porcentagemCompra, data.vendaMenosCompra, 'do valor de compra.', 'Venda')}
                        </div>
                        <div className='text-center'>
                          {this.retornaSpanValoresPorcentagemVenda(data.porcentagemCompraMaisCustos, data.vendaMenosCompraMaisCusto, 'do valor de compra + custos.', 'Venda')}
                        </div>
                      </div>
                    </div>

                    <div className="col-12">  {/* Inicio bloco  */}
                      <div className="col-12 flex">
                        <div className="col-11">
                          <div className='text-center'>
                            <span className="row-expansion-label">Compra: </span>
                            <span className="row-expansion-value">{formatToCurrency(data.valorCompra)}</span>
                          </div>
                          <div className='text-center'>
                            <span className="row-expansion-label">Compra + Custos:</span>
                            <span className="row-expansion-value">{formatToCurrency(data.valorCompraMaisCustos)}</span>
                          </div>
                        </div>
                      </div>
                      <div className="col-11 flex flex-column justify-content-center align-items-center">
                        <div className='text-center'>
                          {this.retornaSpanValoresPorcentagemCompra(data.porcentagemCompraxFipe, data.CompraMenosFipe, 'da FIPE.', 'Compra')}

                        </div>
                        <div className='text-center'>
                          {this.retornaSpanValoresPorcentagemCompra(data.porcentagemCompraMercadoCompra, data.CompraMenosCompraMercado, 'da compra do mercado.', 'Compra')}
                        </div>
                        <div className='text-center'>
                          {this.retornaSpanValoresPorcentagemCompra(data.porcentagemMenorVendaWebMotorsCompra, data.CompraMenosMenorVendaWebMotorsCompra, 'da menor venda na WebMotors.', 'Compra')}
                        </div>

                      </div>
                    </div>

                  </div>

                </div>
              </div>

              {/* Botões ação */}
              <div className="row-expansion-buttons col-12 flex no-padding">
                <div
                  className="md:m-5"
                />
                <AppButton
                  label="Cadastro"
                  className="row-expansion-button"
                  onClick={() => { this.setState({ visibleVeiculoModal: true }) }}
                />
                <AppButton label="Precificar" className="ml-1 row-expansion-button" onClick={() => this.setState({ visibleModalSimulacaoVenda: true })} />
                <AppButton
                  label="Atualizar dados"
                  className="ml-1 row-expansion-button"
                  onClick={() => {
                    this.getAll()
                    showSuccessMessage('Dados atualizados!')
                  }}
                />
                <AppButton
                  label="Alterar FIPE"
                  className="ml-1 row-expansion-button"
                  onClick={() => this.setState({ visibleSelecionarModelosFipeModal: true })}
                />
                <AppButton
                  label="Integrar"
                  className="ml-1 row-expansion-button"
                  onClick={() => { this.setState({ visibleSelecionarModelosReferenciasModal: true }) }}
                />
                <div
                  className="md:m-5"
                />
                <AppButton
                  icon="pi pi-info-circle info-selection"
                  className="expansion-button"
                  onClick={() => { this.setState({ visibleModalAlertas: true }) }}
                />

              </div>
            </div>
            <ModalAnaliseEstoqueAlertas
              visible={this.state.visibleModalAlertas}
              onHide={() => this.setState({ visibleModalAlertas: false })}
              diasPatio={data.dias_est}
              alertaPatio={data.alertaDiasEstoque}
              alertaOrdemServico={data.alertaOSAberta}
              AlertaReservado={data.alertaVeiculoReservado}
              AlertaBloqueado={data.alertaVeiculoBloqueado}
              alertaDemonstracao={data.alertaVeiculoDemonstracao}
              alertaKMAlta={data.alertaKMAlta}
              clienteReserva={data.clienteReserva}
              vendedorReserva={data.vendedorReserva}
              vendedorBloqueio={data.nomeVendedorBloqueio}
              descricaoBloqueio={data.descricaoBloqueio}
              localizacaoDemonstracao={data.localizacaoDemonstracao}
              diasDemonstracao={data.dias_demonstracao}
            />
            <VeiculoModal
              visible={this.state.visibleVeiculoModal}
              hide={() => this.setState({ visibleVeiculoModal: false })}
              onSaveModal={this.onSaveModalVeiculo}
              selected={data}
              codigoSelecionado={data.codigo_vei}
              primaryKey={data?.codigo_vei}
            />
            <SelecionarModelosReferenciaModal
              visible={this.state.visibleSelecionarModelosReferenciasModal}
              onHide={() => {
                this.setState({ visibleSelecionarModelosReferenciasModal: false })
              }}
              onSaveVeiculo={this.onSaveVeiculoIntegracao}
              veiculo={data}
            />
            <SelecionarModelosFipeModal
              visible={this.state.visibleSelecionarModelosFipeModal}
              onHide={() => this.setState({ visibleSelecionarModelosFipeModal: false })}
              veiculo={data}
              onModeloConfirmado={this.onSaveVeiculoIntegracao}
            />
            <ModalSimulacaoVenda
              visible={this.state.visibleModalSimulacaoVenda}
              onHide={() => this.setState({ visibleModalSimulacaoVenda: false })}
              veiculo={data}
              onSaveVeiculo={this.salvarPrecoVeiculoSimulacaoVenda}
            />
          </div>
        )
      }

      const headerGroup = (
        <ColumnGroup>

          <Row>
            <Column header="" colSpan={1} style={{ width: '20%' }} />
            <Column header="VENDA X" colSpan={5} style={{ border: '2px solid #E8E9EB' }} />
            <Column header="COMPRA X" colSpan={3} style={{ border: '2px solid #E8E9EB' }} />
            <Column header="" colSpan={1} />
          </Row>
          <Row>
            <Column header="VEÍCULO" sortable field='descricao_vei' style={{ witdh: '200px' }} />
            <Column header="FIPE" sortable field="valfip_vei" style={{ borderLeft: '2px solid #E8E9EB' }} />
            <Column header="COMPRA MERCADO" sortable field="porcentagemCompraMercado" />
            <Column header="MENOR VENDA WEBMOTOR" sortable field="preweb_vei" />
            <Column header="COMPRA" sortable field="preco_pre" />
            <Column header="COMPRA + CUSTOS" sortable field="precus_pre" />
            <Column header="FIPE" sortable field="valfip_vei" style={{ borderLeft: '2px solid #E8E9EB' }} />
            <Column header="COMPRA MERCADO" sortable field="precom_pre" />
            <Column header="MENOR VENDA WEBMOTOR" sortable field="preweb_vei" style={{ borderRight: '2px solid #E8E9EB' }} />
            <Column header="ALERTAS" sortable field="prepro_pre" />
          </Row>
        </ColumnGroup>
      )

      return (
        <Page>
          {!CommonHelper.isDesktop() && (
            <ScrollTop threshold={1200} />
          )}
          <div className="flex justify-content-between align-items-center page-header">
            <h4>Análise de estoque</h4>
            <div className="page-header-buttons">
              <AppButton
                label="Atualizar dados - Estoque"
                icon="pi pi-plus"
                onClick={() => {
                  this.getAll()
                  showSuccessMessage('Dados atualizados!')
                }}/>
            </div>
          </div>
          <Container>
            <DataList
              headerColumnGroup={headerGroup}
              data={state.list}
              selected={state.selected}
              onSelect={e => this.onSelect(e)}
              onRowClick={(e) => this.handleRowExpansion(e.data)}
              rows={state.rows}
              totalRecords={state.totalRecords}
              first={state.first}
              onPage={this.onPageChange}
              expandedRows={state.expandedRows}
              rowExpansionTemplate={rowExpansionTemplate}
              page={state.page}
              showFilter={true}
              filterPlaceholder="Procurar veículos"
              filterName="descricaoFiltro"
              filterOnChange={this.handleChangeFilter}
              onFilter={this.onFilter}
              expandedFiltersTemplate={this.expandedFiltersTemplate}

            // className='flex'
            >
              {getColunasHabilitadas(columns, 'analiseEstoque')}
            </DataList>
          </Container>

        </Page>
      )
    }
}

export default AnaliseEstoqueVeiculos
