import React, { Component } from 'react'
import ReactToPrint, { PrintContextConsumer } from 'react-to-print'
import AppButton from '../../../components/layout/AppButton'
import { showErrorMessage, showSuccessMessage } from '../../../components/utils/Message'
import CommonHelper from '../../../helpers/CommonHelper'
import { getColunasHabilitadas } from '../../../helpers/tableConfigs'
import { atualizarQuantidadeNegociacoesPendenteAprovacao, generateMenuSideBar } from '../../../redux/actions'
import { dispatch, getStore } from '../../../redux/store'
import PermissaoService from '../../../services/cadastro/pessoa/PermissaoService'
import AprovacaoNegociacaoService from '../../../services/vendas/negociacao/AprovacaoNegociacaoService'
import DropdownMenu from '../../layout/DropdownButton/DropdownMenu'
import TablePreferences from '../TablePreferences'
import ReportContentGenerator from './ReportContentGenerator'
import ReportDataToXlsx from './ReportDataToXlsx'
import ReportHtmlToPrint from './ReportHtmlToPrint'
import './_report.scss'

class ReportComponent extends Component {
  constructor (props) {
    super(props)

    this.state = {
      reportColumnsWidths: null,
      sortField: null,
      sortOrder: null,
      reportContent: null,
      reportFiltroLegenda: null,
      visiblePreferencesModal: false,
      visibleReportFiltroModal: false,
      reportDataToXlsx: null,
      reportTitleInPascalCase: CommonHelper.formatStringToPascalCase(this.props.reportTitle),
      filtroFormValues: {},
      loading: true,
      permissaoParaAprovarNegociacoes: false,
      codNegParaAprovar: null,
      allFilterConfigs: {},
      codigoNeg: false
    }

    this.reportHtmlToPrintRef = null
  }

  async componentDidMount (prevProps) {
    this.renderReportContent()
    const permissaoParaAprovarNegociacoes = await PermissaoService.getByCodigo('12')
    this.setState({ permissaoParaAprovarNegociacoes, permissaoParaAprovarNegociacoes })
    if (this.isEmpresaDiferente(prevProps)) {
      this.resetarFiltros()
    }
  }

  componentDidUpdate (prevProps, prevState) {
    if (this.isEmpresaDiferente(prevProps)) {
      this.resetarFiltros()
    }
    if (
      this.isReportDataDifferent(prevProps) ||
      this.isTotalizadoresDifferent(prevProps) ||
      this.isSortFieldOrSortOrderDifferent(prevState) ||
      this.isReportColumnsWidthsDifferent(prevState)
    ) {
      this.renderReportContent()
      const { empresas } = this.state.filtroFormValues
      const empresaLogada = empresas.filter((empresa) => empresa.codigo_pes === getStore().empresaLogada.codigo_emp).shift()
      empresas.splice(empresas.indexOf(empresaLogada), 1)
    }
  }

  isEmpresaDiferente (prevProps) {
    // console.log(prevProps.empresaSelecionada)
    // return prevProps.empresaSelecionada !== this.props.empresaSelecionada
  }

  // Adicione esta função para resetar os filtros
  resetarFiltros () {
    this.setState({ filtroFormValues: {} })
  }

  isReportDataDifferent (prevProps) {
    return prevProps.reportData !== this.props.reportData
  }

  isTotalizadoresDifferent (prevProps) {
    return prevProps.totalizadores !== this.props.totalizadores
  }

  isSortFieldOrSortOrderDifferent (prevState) {
    return (prevState.sortField !== this.state.sortField) || (prevState.sortOrder !== this.state.sortOrder)
  }

  isReportColumnsWidthsDifferent (prevState) {
    return prevState.reportColumnsWidths?.length !== this.state.reportColumnsWidths?.length
  }

  renderReportContent () {
    this.startLoading(this.generateReportContent)
  }

  generateReportContent () {
    const props = this.props
    const state = this.state

    const colunasHabilitadas = getColunasHabilitadas(props.columns, state.reportTitleInPascalCase)

    const reportDataToXlsx = this.createReportDataToXlsxObject(colunasHabilitadas)

    const reportContentGenerator = new ReportContentGenerator(
      props.reportData,
      colunasHabilitadas,
      props.grouping,
      reportDataToXlsx,
      props.totalizadores,
      state.reportColumnsWidths,
      state.sortField,
      state.sortOrder,
      this.onSort.bind(this)
    )

    const reportContent = reportContentGenerator.generateReport()

    this.setState({ reportContent }, this.stopLoading)
    this.setState({ allFilterConfigs: JSON.parse(localStorage.getItem('FilterConfigs')) })
    this.setState({ codigoNeg: state.allFilterConfigs?.RelatórioDeLucroDeVendas_FilterConfig?.form?.negociacao?.codigo_neg })
  }

  createReportDataToXlsxObject (colunasHabilitadas) {
    const reportDataToXlsx = new ReportDataToXlsx(this.state.reportTitleInPascalCase, colunasHabilitadas, this.props.totalizadores)

    this.setState({ reportDataToXlsx })

    return reportDataToXlsx
  }

  aprovarNegociacao = async () => {
    try {
      await AprovacaoNegociacaoService.aprovarNegociacao(this.state.codNegParaAprovar)
      dispatch(await atualizarQuantidadeNegociacoesPendenteAprovacao())
      dispatch(generateMenuSideBar())

      showSuccessMessage('Negociação aprovada com sucesso!')
    } catch (error) {
      showErrorMessage(error.message || 'Ocorreu um erro inesperado ao tentar aprovar a negociação')
    }
  }

  handleAprovarButtonClick = async () => {
    await this.verificaPermissaoParaAprovarNegComManutencoesEmAberto()
  }

  verificaPermissaoParaAprovarNegComManutencoesEmAberto = async () => {
    try {
      const pedirConfirmacaoAprovarNegComManutencaoEmAberto = await AprovacaoNegociacaoService
        .verificaPermissaoParaAprovarNegComManutencoesEmAberto(this.state.codNegParaAprovar)

      if (pedirConfirmacaoAprovarNegComManutencaoEmAberto) {
        this.showCustomConfirm(
          'Confirmação de aprovação',
          'Existem manutenções em aberto para os veículos vendidos. Confirmar aprovar a negociação mesmo assim?',
          this.verificaPermissaoParaAprovarNegComOrdemDeServicoEmAberto
        )
      } else {
        this.verificaPermissaoParaAprovarNegComOrdemDeServicoEmAberto()
      }
    } catch (error) {
      showErrorMessage(error.message || 'Ocorreu um erro inesperado ao tentar aprovar a negociação')
    }
  }

  verificaPermissaoParaAprovarNegComOrdemDeServicoEmAberto = async () => {
    try {
      const pedirConfirmacaoAprovarNegComOrdemDeServicoEmAberto = await AprovacaoNegociacaoService
        .verificaPermissaoParaAprovarNegComOrdemDeServicoEmAberto(this.state.codNegParaAprovar)

      if (pedirConfirmacaoAprovarNegComOrdemDeServicoEmAberto) {
        this.showCustomConfirm(
          'Confirmação de aprovação',
          'Existem ordem de serviço em aberto para os veículos vendidos. Confirmar aprovar a negociação mesmo assim?',
          this.aprovarNegociacao
        )
      } else {
        this.aprovarNegociacao()
      }
    } catch (error) {
      showErrorMessage(error.message || 'Ocorreu um erro inesperado ao tentar aprovar a negociação')
    }
  }

  onSort (e) {
    this.setState({ sortField: e.sortField })
    this.setState({ sortOrder: e.sortOrder })
  }

  startLoading (callBack) {
    this.setState({ loading: true }, callBack)
  }

  stopLoading () {
    this.setState({ loading: false }, this.addEventListeners)
  }

  render () {
    const props = this.props
    const state = this.state
    console.log(state.reportFiltroLegenda)
    let imprimirReport

    const itensAcao = [
      {
        label: 'Filtros',
        command: () => this.setState({ visibleReportFiltroModal: true })
      },
      {
        label: 'Customizar colunas',
        command: () => this.setState({ visiblePreferencesModal: true })
      },
      {
        label: 'Gerar Excel',
        command: () => state.reportDataToXlsx.downloadSheetToFileXlsx()
      },
      {
        label: 'Imprimir',
        command: () => imprimirReport()
      }
    ]
    return !state.loading && (
      <>
        <div className="flex justify-content-end mt-3 mr-2">
          {state.codigoNeg && state.permissaoParaAprovarNegociacoes && <AppButton
            label="Aprovar"
            onClick={() => this.setState({ codNegParaAprovar: state.allFilterConfigs.RelatórioDeLucroDeVendas_FilterConfig.form.negociacao.codigo_neg }, this.handleAprovarButtonClick)}
            className={(CommonHelper.isDesktop() ? 'mr-3' : 'mb-3') + ' aprovacao-negociacao button'}
          /> }
          <DropdownMenu
            items={itensAcao}
            icon="pi pi-bars"
            label={CommonHelper.isDesktop() ? 'Ações' : ''}
          />
        </div>
        <ReportHtmlToPrint
          ref={(ref) => this.reportHtmlToPrintRef = ref}
          reportTitle={props.reportTitle}
          reportContent={state.reportContent}
          reportData={props.reportData}
          legendaReport={props.legendaReport}
          reportFiltroLegenda={state.reportFiltroLegenda}
          ReportFooterComponent={props.ReportFooterComponent}
          reportColumnsQuantity={props.columns.length}
          isReportColumnsWidthsAlreadySet={!!state.reportColumnsWidths}
          setReportColumnsWidths={(reportColumnsWidths) => this.setState({ reportColumnsWidths })}
        />
        <props.ReportFiltroModalComponent
          form={state.filtroFormValues}
          setReportData={reportData => props.setReportData(reportData)}
          setForm={(filtroFormValues, callBack) => this.setState({ filtroFormValues }, callBack)}
          setReportFiltroLegenda={reportFiltroLegenda => this.setState({ reportFiltroLegenda })}
          visible={state.visibleReportFiltroModal}
          onHide={() => this.setState({ visibleReportFiltroModal: false })}
          reportName={state.reportTitleInPascalCase}
        />
        <TablePreferences
          title="Preferências do relatório"
          tableName={state.reportTitleInPascalCase}
          columns={props.columns}
          visible={state.visiblePreferencesModal}
          hide={() => this.setState({ visiblePreferencesModal: false })}
          reportTable
        />
        <ReactToPrint documentTitle={state.reportTitleInPascalCase} content={() => this.reportHtmlToPrintRef}>
          <PrintContextConsumer>
            {({ handlePrint }) => imprimirReport = handlePrint}
          </PrintContextConsumer>
        </ReactToPrint>
      </>
    )
  }
}

export default ReportComponent
