import moment from 'moment'
import { Checkbox } from 'primereact/checkbox'
import React, { useEffect, useState } from 'react'
import AutoCompleteInput from '../../../../../components/inputs/AutoCompleteInput'
import NumberInput from '../../../../../components/inputs/NumberInput'
import PlacaInput from '../../../../../components/inputs/PlacaInput'
import SelectInput from '../../../../../components/inputs/SelectInput'
import TextAreaInput from '../../../../../components/inputs/TextAreaInput'
import TextInput from '../../../../../components/inputs/TextInput'
import PessoaAutocompleteTemplate from '../../../../../components/inputs/options/PessoaAutocompleteTemplate'
import SearchClearInput from '../../../../../components/inputs/searchInput/SearchClearInput'
import AppButton from '../../../../../components/layout/AppButton'
import {
  showErrorMessage,
  showSuccessMessage,
  showWarnMessage
} from '../../../../../components/utils/Message'
import { getModeloDTO } from '../../../../../dtos/cadastro/veiculo/ModeloDTO'
import { getVeiculoDTO } from '../../../../../dtos/cadastro/veiculo/VeiculoDTO'
import CommonHelper from '../../../../../helpers/CommonHelper'
import { formatCurrencyToNumber, formatDocument, formatToCurrency } from '../../../../../helpers/formaters'
import { useValidateInput } from '../../../../../helpers/useValidateInput'
import { PessoaModel } from '../../../../../models/cadastro/pessoa/PessoaModel'
import ConectaCarrosService from '../../../../../services/Integracao/conectaCarros/ConectaCarrosService'
import PessoaService from '../../../../../services/cadastro/pessoa/PessoaService'
import ClasseICMSProdutoService from '../../../../../services/cadastro/produto/ClasseICMSProdutoService'
import ClassificacaoFiscalService from '../../../../../services/cadastro/produto/ClassificacaoFiscalService'
import AvaliacaoService from '../../../../../services/cadastro/veiculo/AvaliacaoService'
import ModeloService from '../../../../../services/cadastro/veiculo/ModeloService'
import VeiculoService from '../../../../../services/cadastro/veiculo/VeiculoService'
import ConfigService from '../../../../../services/configuracao/ConfigService'
import { PessoaFisica } from '../../../../../views/cadastro/pessoa/fisica/PessoaFisica'
import { PessoaJuridica } from '../../../../../views/cadastro/pessoa/juridica/PessoaJuridica'
import Modelo from '../../../../../views/cadastro/veiculo/modelo/Modelo'
import SelecionarModelosFipeModal from '../../../../vendas/negociacao/avaliacao/SelecionarModeloFipeModal'
import SelecionarModelosReferenciaModal from '../../../../vendas/negociacao/avaliacao/SelecionarModelosReferenciaModal'
import AlterarAcessoriosModal from '../AlterarAcessoriosModal'

const DadosPrincipaisVei = ({ form, edicaoHabilitada, camposObrigatorios }) => {
  const { isFormFieldValid } = useValidateInput(form)

  const [colors, setColors] = useState([])
  const [combustiveis, setCombustiveis] = useState([])
  const [sugestaoProprietarios, setSugestaoProprietarios] = useState([])
  const [sugestaoModelos, setSugestaoModelos] = useState([])
  const [categorias, setCategorias] = useState([])
  const [tipoPinturas, setTipoPinturas] = useState([])
  const [usaCombustivel, setUsaCombustivel] = useState(true)
  const [classesICMS, setClassesICMS] = useState([])
  const [descricaoAcessoriosVeiculo, setDescricaoAcessoriosVeiculo] = useState('')
  const [visibleAlterarAcessoriosModal, setVisibleAlterarAcessoriosModal] = useState(false)
  const [obrigaCilindrada, setObrigaCilindrada] = useState(true)
  const [visibleSelecionarModelosReferenciasModal, setVisibleSelecionarModelosReferenciasModal] = useState(false)
  const [visibleSelecionarModelosFipeModal, setVisibleSelecionarModelosFipeModal] = useState(false)

  useEffect(() => {
    requisicoesIniciais()
    if (!form.values.placa_vei) {
      form.setFieldValue('codtiv_vei', 'n')
      form.setFieldValue('tipoVeiculo', 'Novo')
    } else {
      form.setFieldValue('codtiv_vei', 'u')
      form.setFieldValue('tipoVeiculo', 'Usado')
    }
    setDescricaoAcessoriosVeiculo(form.values.descricoesAcessorios)
  }, [])

  useEffect(async () => {
    verificaObrigaCilindrada()
    verificaObrigaCombustivel()
  }, [form.values.modelo.tipoVeiculoDenatran, form.values.placa_vei])

  async function verificaObrigaCilindrada () {
    form.setFieldValue('combustivel.usacil_tvd', obrigaCilindrada)
    if (!form.values.veiculo?.cilind_vei && form.values.modelo.tipoVeiculoDenatran?.usacil_tvd) {
      if (form.values?.codtiv_vei === 'u') {
        ConfigService.getValor(1496).then(config => {
          form.setFieldValue('obrigaCilindrada', config)
          setObrigaCilindrada(config)
        }).catch(erro => {
          console.log(erro)
        })
      } else {
        if (form.values?.codtiv_vei === 'n' && form.values.modelo.tagnov_mod) {
          setObrigaCilindrada(true)
          form.setFieldValue('obrigaCilindrada', true)
          return
        }
        setObrigaCilindrada(false)
        form.setFieldValue('obrigaCilindrada', false)
      }
    } else {
      setObrigaCilindrada(false)
      form.setFieldValue('obrigaCilindrada', false)
    }
  }

  async function verificaObrigaCombustivel () {
    form.setFieldValue('combustivel.usacom_tvd', usaCombustivel)
    if (form.values.modelo.tipoVeiculoDenatran?.usacom_tvd === 0) {
      setUsaCombustivel(false)
    } else {
      setUsaCombustivel(true)
    }
  }

  async function requisicoesIniciais () {
    await Promise.all([
      getCores(),
      getCombustiveis(),
      getCategorias(),
      getTipoPintura(),
      getClassesICMS()
    ])
  }

  const buscarPlaca = async (placaVei) => {
    if (!placaVei) {
      form.setFieldValue('classeICMS', classesICMS[3])
      form.setFieldValue('produto.codcla_pro', 5)
      form.setFieldValue('codtiv_vei', 'n')
      form.setFieldValue('tipoVeiculo', 'Novo')
    } else {
      form.setFieldValue('classeICMS', classesICMS[4])
      form.setFieldValue('produto.codcla_pro', 1)
      form.setFieldValue('codtiv_vei', 'u')
      form.setFieldValue('tipoVeiculo', 'Usado')
    }
    try {
      const veiculo = await VeiculoService.getByPlate(placaVei, form.values.codigo_vei)
      let fipcoc
      let dataFipe
      if (veiculo) {
        showWarnMessage(`Placa já cadastrada! Carregando os dados do veículo ${veiculo.codigo_vei}`)
        const veiDto = getVeiculoDTO(veiculo)
        form.setValues(veiDto)
        setDescricaoAcessoriosVeiculo(veiDto.descricoesAcessorios)
        fipcoc = veiculo.fipcoc_vei
        dataFipe = veiculo.datfip_vei
      } else {
        const avaliacao = await AvaliacaoService.getAvaliacaoCompletaPorPlaca(placaVei)

        if (avaliacao) {
          showSuccessMessage(`Os dados foram obtidos da avaliação ${avaliacao.codigo_ava}`)

          form.setFieldValue('chassi_vei', avaliacao.chassi_ava || form.values.chassi_vei)
          if (avaliacao.proprietario?.codigo_pes) {
            form.setFieldValue('proprietario', avaliacao.proprietario?.codigo_pes)
          }
          form.setFieldValue('modelo', avaliacao.modelo || form.values.modelo)
          form.setFieldValue('km_vei', avaliacao.km_ava || form.values.km_vei)
          form.setFieldValue('codfip_vei', avaliacao.codfip_ava || form.values.codfip_vei)
          form.setFieldValue('anomod_vei', avaliacao.anomod_ava || form.values.anomod_vei)
          form.setFieldValue('anofab_vei', avaliacao.anofab_ava || form.values.anofab_vei)
          if (avaliacao.corPredominante?.codigo_cor) {
            form.setFieldValue('cor', avaliacao.corPredominante)
          }
          if (avaliacao.combustivel?.codigo_com) {
            form.setFieldValue('combustivel', avaliacao.combustivel)
          }
          form.setFieldValue('codcoc_vei', avaliacao.codcoc_ava || form.values.codcoc_vei)
          form.setFieldValue('descoc_vei', avaliacao.descoc_ava || form.values.descoc_vei)
          form.setFieldValue('tivcoc_vei', avaliacao.tivcoc_ava || form.values.tivcoc_vei)
          form.setFieldValue('fipcoc_vei', avaliacao.fipcoc_ava || form.values.fipcoc_vei)
          form.setFieldValue('codfip_vei', avaliacao.codfip_ava || form.values.codfip_vei)
          form.setFieldValue('desfip_vei', avaliacao.desfip_ava || form.values.desfip_vei)
          form.setFieldValue('valfip_vei', avaliacao.valfip_ava || form.values.valfip_vei)
          form.setFieldValue('datfip_vei', avaliacao.datfip_ava || form.values.datfip_vei)
          fipcoc = avaliacao.fipcoc_ava
          dataFipe = avaliacao.datfip_ava

          if (avaliacao?.acessorios) {
            form.setFieldValue(
              'acessorios',
              avaliacao.acessorios.map((acessorio) => ({
                codace_acv: acessorio.codigo_ace
              }))
            )
          }
        }
      }

      if ((fipcoc && !dataFipe) || (fipcoc && !CommonHelper.isSameMonthAndYear(dataFipe))) {
        const valorFipeAtual = await ConectaCarrosService.getValorFipeAtual(fipcoc)
        if (valorFipeAtual?.data) {
          form.setFieldValue('datfip_vei', valorFipeAtual.data.data_ultima_alteracao)
          form.setFieldValue('valfip_vei', valorFipeAtual.data.valor_fipe)
        }
      }
    } catch (error) {
      showErrorMessage(error.message || 'Ocorreu um erro inesperado ao tentar buscar a placa!')
    }
  }

  const handleChangeProprietario = (e) => {
    form.setFieldValue('proprietario', e.target.value)
    form.setFieldValue('proprietario.nomraz_pes', e.target.value)
  }

  const handleChangeModelo = (e) => {
    if (!e.target.value) {
      form.setFieldValue('modelo', { descri_mod: '' })
    } else {
      form.setFieldValue('modelo.descri_mod', e.target.value)
    }
  }

  const handleChangeRepasse = (e) => {
    form.setFieldValue('repass_vei', !e.target.value)
  }

  const handleChangeChassiRemarcado = (e) => {
    form.setFieldValue('charem_vei', !e.target.value)
  }

  const handleChangeCheck = (e) => {
    form.setFieldValue(e.target.name, !e.target.value)
  }

  function onSaveModalProprietario (proprietario) {
    onSelectProprietario({ value: proprietario.pessoa })
    isFormFieldValid('proprietario.codigo_pes')
  }

  const onSaveModalModelo = async (modelo) => {
    onSelectModelo({ value: modelo })
  }

  const getClassesICMS = async () => {
    try {
      const classesICMS = await ClasseICMSProdutoService.getAll()
      setClassesICMS(classesICMS)
    } catch (e) {
      showErrorMessage(e.message || 'Ocorreu um erro inesperado ao tentar buscar as classes de ICMS!')
    }
  }

  async function getCores () {
    try {
      const colors = await VeiculoService.getAllColors()
      await setColors(colors)
    } catch (e) {
      showErrorMessage(e.message || 'Ocorreu um erro inesperado ao tentar buscar as cores!')
    }
  }

  async function getTipoPintura () {
    try {
      const tipoPinturas = await VeiculoService.getAllTipoPinturas()
      await setTipoPinturas(tipoPinturas)
    } catch (e) {
      showErrorMessage(e.message || 'Ocorreu um erro inesperado ao tentar buscar as pinturas!')
    }
  }

  async function getCombustiveis () {
    try {
      const combustiveis = await VeiculoService.getAllFuels()
      setCombustiveis(combustiveis)
    } catch (e) {
      showErrorMessage(e.message || 'Ocorreu um erro inesperado ao tentar buscar os tipos de combustíveis!')
    }
  }

  async function getCategorias () {
    try {
      const categorias = await VeiculoService.getAllCategorias()
      setCategorias(categorias)
    } catch (e) {
      console.error(e.message)
      showErrorMessage(e.message || 'Ocorreu um erro inesperado ao tentar buscar as categorias!')
    }
  }

  const handleChangeUnicil = (e) => {
    form.setFieldValue('unicil_vei', !e.target.value)
  }

  const onSelectProprietario = async (e) => {
    await form.setFieldValue('proprietario', e.value)
    form.setFieldValue('uf_vei', e?.uf_pes)
    form.setFieldValue('cidade_vei', e?.cidade_pes)
  }

  const onSelectModelo = async (e) => {
    form.setFieldValue('modelo', e.value)

    const classificacaoFiscal = form.values.modelo.classificacaofiscal

    if (classificacaoFiscal != null) {
      form.setFieldValue('produto.codclf_pro', classificacaoFiscal.codigo_clf)
      form.setFieldValue('classificacaoFiscal', classificacaoFiscal)

      try {
        const {
          substituicaoTributaria,
          pisCofins
        } = await ClassificacaoFiscalService.getSubstituicaoTributariaPisCofins({
          ncm_clf: classificacaoFiscal.ncm_clf,
          codigo_clf: classificacaoFiscal.codigo_clf
        })

        setSubstituicaoTributaria(substituicaoTributaria)
        setPisCofins(pisCofins)
      } catch (error) {
        showErrorMessage(error.message || 'Ocorreu um erro inesperado ao buscar a substituição tributaria!')
      }
    }

    form.validateField('modelo.codigo_mod')
  }

  const sugerirModelos = async () => {
    try {
      const modelos = await VeiculoService.filterModels(form.values.modelo.descri_mod)
      setSugestaoModelos(modelos)
    } catch (error) {
      showErrorMessage(error.message || 'Ocorreu um erro inesperado ao sugerir um modelo!')
    }
  }

  const sugerirProprietarios = async () => {
    try {
      const pessoas = await PessoaService.filterAutocomplete(form.values.proprietario.nomraz_pes)

      setSugestaoProprietarios(pessoas)
    } catch (error) {
      showErrorMessage(error.message || 'Ocorreu um erro inesperado ao sugerir um proprietario!')
    }
  }

  const onClearModeloReferencia = () => {
    form.setFieldValue('tivcoc_vei', null)
    form.setFieldValue('codcoc_vei', null)
    form.setFieldValue('descoc_vei', '')
    form.setFieldValue('fipcoc_vei', null)
    form.setFieldValue('desfip_vei', '')
  }

  const onClearModeloFipe = () => {
    form.setFieldValue('fipcoc_vei', null)
    form.setFieldValue('desfip_vei', '')
  }

  const handleModeloConfirmado = (veiculo) => {
    form.setValues(veiculo)
  }

  const abrirModalModelosFipe = () => {
    if (!form.values.codcoc_vei) {
      showErrorMessage('Informe o modelo de referência de integração primeiro!')
      return false
    }
    setVisibleSelecionarModelosFipeModal(true)
  }

  const handleChangeValorFipe = (e) => {
    form.setFieldValue(e.target.name, formatCurrencyToNumber(e.target.value))
    form.setFieldValue('datfip_vei', null)
  }

  const handleChangePlaca = async (value) => {
    form.setFieldValue('placa_vei', value)
    const placa = CommonHelper.clearDigitsSpecialCharsExceptLetters(value)
    if (edicaoHabilitada && placa?.length === 7) {
      await buscarPlaca(placa)
    }
  }

  return (
    <div className="formgrid grid fluid">
      <div className="field col-12 md:col-12">
        <TextInput
          label="Descrição"
          placeholder="Descrição"
          name="descri_pro"
          value={form.values.produto.descri_pro}
          disabled={true}
          className="inputfield w-full"
        />
      </div>
      <div className="field col-12 md:col-4">
        <TextInput
          label="Situação"
          placeholder="Situação"
          name="descri_ves"
          className="inputfield w-full"
          value={form.values.produto?.produtoEmpresa?.veiculoSituacao?.descri_ves}
          disabled={true}
        />
      </div>
      <div className="field col-12 md:col-4">
        <TextInput
          label="Disponibilidade"
          placeholder="Disponibilidade"
          name="descri_vdi"
          className="inputfield w-full"
          value={form.values.produto?.produtoEmpresa?.veiculoDisponibilidade?.descri_vdi}
          disabled={true}
        />
      </div>
      <div className="field col-12 md:col-4">
        <TextInput
          label="Veículo ofertado em"
          name="ofeemp_vei"
          placeholder="Veículo ofertado em"
          disabled={true}
          className="inputfield w-full"
          value={form.values.ofertaEmpresa?.codigo_pes ? (form.values.ofertaEmpresa?.codigo_pes + ' - ' + form.values.ofertaEmpresa?.nomraz_pes) : null}
        />
      </div>
      <div className="field col-5 sm:col-4 md:col-2">
        <PlacaInput
          label="Placa"
          name="placa_vei"
          placeholder="Placa"
          value={form.values.placa_vei}
          onChange={async (value) => await handleChangePlaca(value)}
          disabled={!edicaoHabilitada}
          className="inputfield w-full"
          camposObrigatorios={camposObrigatorios}
          form={form}
        />
      </div>
      <div className="field col-7 sm:col-3 md:col-2">
        <TextInput
          label="Renavam"
          name="renava_vei"
          placeholder="Renavam"
          value={form.values.renava_vei}
          onChange={(value) => form.setFieldValue('renava_vei', value)}
          disabled={!edicaoHabilitada}
          mask="99999999999"
          camposObrigatorios={camposObrigatorios}
          form={form}
        />
      </div>
      <div className="field col-12 sm:col-5 md:col-2">
        <TextInput
          label="Chassi"
          name="chassi_vei"
          placeholder="Chassi"
          value={form.values.chassi_vei}
          maxLength={17}
          onChange={form.handleChange}
          disabled={!edicaoHabilitada}
          camposObrigatorios={camposObrigatorios}
          form={form}
        />
      </div>
      <div className="field col-12 sm:col-8 md:col-6">
        <AutoCompleteInput
          //AutoComplete
          name="modelo.codigo_mod"
          field="descri_mod"
          label="Modelo"
          value={form.values.modelo}
          placeholder="Digite para pesquisar um modelo."
          disabled={!edicaoHabilitada}
          onSelect={(e) => onSelectModelo(e)}
          suggestions={sugestaoModelos}
          completeMethod={sugerirModelos}
          onChange={handleChangeModelo}
          camposObrigatorios={camposObrigatorios}
          form={form}
          //RegisterModal
          selected={form.values.modelo}
          onSaveModal={onSaveModalModelo}
          onDeleteModal={() => onSelectModelo({})}
          modalHeader={form.values.modelo?.codigo_mod ? `Modelo - ${form.values.modelo?.codigo_mod}` : 'Cadastro de modelo'}
          ComponenteCadastro={Modelo}
          // FiltroModal
          filtrotitle="Pesquisa de modelo"
          service={ModeloService}
          model={getModeloDTO}
          columns={[
            { campo: 'codigo_mod', nome: 'Código' },
            { campo: 'descri_mod', nome: 'Descrição' },
            { campo: 'marca.descri_mar', nome: 'Marca' }
          ]}
          //FiltroModal && RegisterModal
          primarykeyname="codigo_mod"
        />
      </div>
      <div className="field col-6 sm:col-4 md:col-2">
        <TextInput
          label="Ano fabricação"
          name="anofab_vei"
          placeholder="Ano fabricação"
          value={form.values.anofab_vei}
          onChange={(value) => form.setFieldValue('anofab_vei', value)}
          mask="9999"
          maskChar=""
          disabled={!edicaoHabilitada}
          camposObrigatorios={camposObrigatorios}
          form={form}
        />
      </div>
      <div className="field col-6 sm:col-3 md:col-2">
        <TextInput
          label="Ano modelo"
          name="anomod_vei"
          placeholder="Ano modelo"
          value={form.values.anomod_vei}
          onChange={(value) => form.setFieldValue('anomod_vei', value)}
          mask="9999"
          maskChar=""
          disabled={!edicaoHabilitada}
          camposObrigatorios={camposObrigatorios}
          form={form}
        />
      </div>
      <div className="field col-12 sm:col-9 md:col-2">

        <SelectInput
          label="Combustível "
          name="combustivel.codigo_com"
          value={form.values.combustivel}
          options={combustiveis}
          onChange={(e) => {
            form.setFieldValue('combustivel', e.target.value || {})
            form.validateField('combustivel.codigo_com')
          }}
          filter={CommonHelper.isDesktop()}
          optionLabel="descri_com"
          dataKey="codigo_com"
          noFloatLabel
          disabled={!edicaoHabilitada}
          showClear={form.values.combustivel?.codigo_com}
          camposObrigatorios={usaCombustivel ? camposObrigatorios : false}
          form={form}
        />
      </div>

      <div className="field col-12 md:col-6">
        <AutoCompleteInput
          //AutoComplete
          label="Proprietário"
          name="proprietario.codigo_pes"
          field="nomraz_pes"
          value={form.values.proprietario && form.values.proprietario?.nomraz_pes}
          placeholder="Digite para pesquisar um proprietário"
          disabled={!edicaoHabilitada}
          onSelect={async (e) => {
            await onSelectProprietario(e)
            form.setFieldTouched('proprietario.codigo_pes')
          }}
          suggestions={sugestaoProprietarios}
          completeMethod={sugerirProprietarios}
          onChange={handleChangeProprietario}
          itemTemplate={PessoaAutocompleteTemplate}
          camposObrigatorios={camposObrigatorios}
          form={form}
          //RegisterModal
          selected={form.values.proprietario}
          onSaveModal={onSaveModalProprietario}
          onDeleteModal={async () => await onSelectProprietario({})}
          ComponenteCadastro={[{
            label: 'Pessoa física',
            component: PessoaFisica,
            header: form.values.proprietario.codigo_pes ? `Pessoa Física - ${form.values.proprietario.codigo_pes}` : 'Cadastro de pessoa física'
          },
          {
            label: 'Pessoa jurídica',
            component: PessoaJuridica,
            header: form.values.proprietario.codigo_pes ? `Pessoa jurídica - ${form.values.proprietario.codigo_pes}` : 'Cadastro de pessoa jurídica'
          }
          ]}
          // FiltroModal
          filtrotitle="Pesquisa de pessoa"
          service={PessoaService}
          model={PessoaModel}
          columns={[
            { campo: 'codigo_pes', nome: 'Código' },
            { campo: 'nomraz_pes', nome: 'Nome' },
            { campo: 'cgccpf_pes', nome: 'CPF/CNPJ', format: formatDocument }
          ]}
          //RegisterModal && FiltroModal
          primarykeyname="codigo_pes"
        />
      </div>
      <div className="field col-12 sm:col-6 md:col-2">
        <SelectInput
          label="Cor"
          name="cor.codigo_cor"
          value={form.values.cor}
          options={colors}
          onChange={(e) => {
            form.setFieldValue('cor', e.target.value || {})
            form.validateField('cor.codigo_cor')
          }}
          filter={CommonHelper.isDesktop()}
          optionLabel="descri_cor"
          dataKey="codigo_cor"
          noFloatLabel
          disabled={!edicaoHabilitada}
          showClear={form.values.cor?.codigo_cor}
          camposObrigatorios={camposObrigatorios}
          form={form}
        />
      </div>
      <div className="field col-12 sm:col-6 md:col-2">
        <SelectInput
          label="Tipo de pintura"
          name="tipoPintura.codigo_tpp"
          value={form.values.tipoPintura}
          options={tipoPinturas}
          onChange={async (e) => {
            form.setFieldValue('tipoPintura', e.target.value || {})
            form.validateField('tipoPintura.codigo_tpp')
          }}
          filter={CommonHelper.isDesktop()}
          optionLabel="descri_tpp"
          dataKey="codigo_tpp"
          noFloatLabel
          disabled={!edicaoHabilitada}
          showClear={form.values.tipoPintura?.codigo_tpp}
          camposObrigatorios={camposObrigatorios}
          form={form}
        />
      </div>
      <div className="field col-6 sm:col-3 md:col-1">
        <NumberInput
          label="KM"
          name="km_vei"
          placeholder="KM"
          value={form.values.km_vei}
          onChange={(e) => form.setFieldValue('km_vei', e.value)}
          disabled={!edicaoHabilitada}
          min="0"
          useGrouping={false}
          camposObrigatorios={camposObrigatorios}
          form={form}
        />
      </div>
      <div className="field col-6 sm:col-3 md:col-1">
        <TextInput
          label="Condição"
          name="tipoVeiculo"
          placeholder="Condição"
          value={form.values.tipoVeiculo}
          onChange={form.handleChange}
          disabled={true}
          maxLength="30"
          camposObrigatorios={camposObrigatorios}
          form={form}
        />
      </div>

      <div className="field col-12 sm:col-6 md:col-3">
        <SelectInput
          label="Categoria"
          name="categoria.codigo_cat"
          value={form.values.categoria}
          options={categorias}
          onChange={(e) => {
            form.setFieldValue('categoria', e.target.value || {})
            form.validateField('categoria.codigo_cat')
          }}
          optionLabel="descri_cat"
          dataKey="codigo_cat"
          noFloatLabel
          disabled={!edicaoHabilitada}
          showClear={form.values.categoria?.codigo_cat}
          camposObrigatorios={camposObrigatorios}
          form={form}
        />
      </div>
      <div className="field col-6 sm:col-3 md:col-1">

        <TextInput
          label="Cilindrada"
          name="cilind_vei"
          placeholder="Cilindrada"
          value={form.values.cilind_vei}
          onChange={form.handleChange}
          disabled={!edicaoHabilitada}
          keyfilter="pnum"
          camposObrigatorios={obrigaCilindrada ? camposObrigatorios : false}
          form={form}
        />
      </div>
      <div className="field col-3 sm:col-2 md:col-1 checkbox-container">
        <Checkbox
          id="unicil_vei"
          value={form.values.unicil_vei}
          onChange={handleChangeUnicil}
          checked={!form.values.unicil_vei}
          name="unicil_vei"
          disabled={!edicaoHabilitada}
        />
        <label htmlFor="unicil_vei" className="p-checkbox-label mb-0 pl-2">Litros</label>
      </div>
      <div className="field col-3 sm:col-2 md:col-1 checkbox-container">
        <Checkbox
          id="unicil_vei"
          value={form.values.unicil_vei}
          onChange={handleChangeUnicil}
          checked={form.values.unicil_vei}
          name="unicil_vei"
          disabled={!edicaoHabilitada}
        />
        <label htmlFor="unicil_vei" className="p-checkbox-label mb-0 pl-2">cm³</label>
      </div>

      <div className="field col-12 md:col-4">
        <SearchClearInput
          label="Modelo de referência ConectaCarros"
          field="descoc_vei"
          value={form.values.descoc_vei}
          onClear={onClearModeloReferencia}
          onSearch={() => setVisibleSelecionarModelosReferenciasModal(true)}
          placeholder="Clique na lupa para pesquisar"
          disabled={!edicaoHabilitada}
          disabledAutoComplete={true}
          className="inputfield w-full"
        >
          <SelecionarModelosReferenciaModal
            visible={visibleSelecionarModelosReferenciasModal}
            onHide={() => setVisibleSelecionarModelosReferenciasModal(false)}
            veiculo={form.values}
          />
        </SearchClearInput>
      </div>
      <div className="field col-12 md:col-4">
        <SearchClearInput
          label="Modelo FIPE"
          field="desfip_vei"
          value={form.values.desfip_vei}
          onClear={onClearModeloFipe}
          onSearch={() => abrirModalModelosFipe()}
          placeholder="Clique na lupa para pesquisar"
          disabled={!edicaoHabilitada}
          disabledAutoComplete={true}
          className="inputfield w-full"
        >
          <SelecionarModelosFipeModal
            visible={visibleSelecionarModelosFipeModal}
            onHide={() => setVisibleSelecionarModelosFipeModal(false)}
            veiculo={form.values}
            onModeloConfirmado={(veiculo) => handleModeloConfirmado(veiculo)}
          />
        </SearchClearInput>
      </div>
      <div className="field col-6 sm:col-4 md:col-2">
        <TextInput
          label="Valor FIPE"
          placeholder="Valor FIPE"
          name="valfip_vei"
          value={formatToCurrency(form.values.valfip_vei)}
          onChange={handleChangeValorFipe}
          disabled={!edicaoHabilitada}
          maxLength={20}
          className="inputfield w-full"
        />
      </div>
      <div className="field col-6 sm:col-4 md:col-2  checkbox-container" >
        <span>
          {form.values.datfip_vei ? `Referente a ${moment(form.values.datfip_vei).format('MM/YYYY')}` : 'Sem data de referência'}
        </span>
      </div>

      <div className="field col-6 md:col-2 checkbox-container">
        <Checkbox
          id="repass_vei"
          value={form.values.repass_vei}
          onChange={handleChangeRepasse}
          checked={form.values.repass_vei}
          name="repass_vei"
          disabled={!edicaoHabilitada}
        />
        <label htmlFor="repass_vei" className="checkbox-label mb-0 pl-2">
          Repasse
        </label>
      </div>
      <div className="field col-6 md:col-2 checkbox-container">
        <Checkbox
          value={form.values.blinda_vei}
          onChange={handleChangeCheck}
          checked={form.values.blinda_vei}
          name="blinda_vei"
          disabled={!edicaoHabilitada}
        />
        <label htmlFor="blinda_vei" className="checkbox-label mb-0 pl-2">
          Blindado
        </label>
      </div>
      <div className="field col-12 md:col-2 checkbox-container">
        <Checkbox
          id="charem_vei"
          value={form.values.charem_vei}
          onChange={handleChangeChassiRemarcado}
          checked={form.values.charem_vei}
          name="charem_vei"
          disabled={!edicaoHabilitada}
        />
        <label htmlFor="charem_vei" className="checkbox-label mb-0 pl-2">
          Chassi Remarcado
        </label>
      </div>
      <div className="field col-12 md:col-4 checkbox-container">
        <Checkbox
          value={form.values.adadef_vei}
          onChange={handleChangeCheck}
          checked={form.values.adadef_vei}
          name="adadef_vei"
          disabled={!edicaoHabilitada}
        />
        <label htmlFor="adadef_vei" className="checkbox-label mb-0 pl-2">
          Adaptado para deficiência física
        </label>
      </div>

      <div className="field col-12 md:col-2 no-margin flex align-items-center">
        <AppButton
          label="Alterar acessórios"
          type="button"
          className="inputfield w-full"
          onClick={() => setVisibleAlterarAcessoriosModal(true)}
          disabled={!edicaoHabilitada}
        />
      </div>
      {descricaoAcessoriosVeiculo && (
        <div className={(CommonHelper.isDesktop() ? '' : 'mt-3') + ' field col-12 md:col-12'}>
          <TextAreaInput
            label="Acessórios do veículo"
            className="inputfield w-full"
            rows={1}
            maxLength={1000}
            value={descricaoAcessoriosVeiculo}
            disabled
          />
        </div>
      )}
      <AlterarAcessoriosModal
        changeDescricaoAcessoriosVeiculo={(newDescricao) => setDescricaoAcessoriosVeiculo(newDescricao)}
        onHide={() => setVisibleAlterarAcessoriosModal(false)}
        visible={visibleAlterarAcessoriosModal}
        form={form}
      />
    </div>
  )
}

export default DadosPrincipaisVei
