import { classNames } from 'primereact/utils'
import React, { useEffect, useState } from 'react'
import DateInput from '../../../../../../components/inputs/DateInput'
import SelectInput from '../../../../../../components/inputs/SelectInput'
import TextAreaInput from '../../../../../../components/inputs/TextAreaInput'
import TextInput from '../../../../../../components/inputs/TextInput'
import BancoAutocompleteTemplate from '../../../../../../components/inputs/options/BancoAutoCompleteTemplate'
import ContaAutocompleteTemplate from '../../../../../../components/inputs/options/ContaAutoCompleteTemplate'
import SearchInput from '../../../../../../components/inputs/searchInput/SearchInput'
import ResponsavelPagamento from '../../../../../../components/layout/ResponsavelPagamento/ResponsavelPagamento'
import FieldErrorMessage from '../../../../../../components/utils/FieldErrorMessage'
import Modal from '../../../../../../components/utils/Modal'
import RequiredLabel from '../../../../../../components/utils/RequiredLabel'
import { formatToCurrency } from '../../../../../../helpers/formaters'
import { useValidateInput } from '../../../../../../helpers/useValidateInput'
import { ContaModel } from '../../../../../../models/cadastro/financeiro/ContaModel'
import BancoService from '../../../../../../services/cadastro/financeiro/BancoService'
import ContaBancariaService from '../../../../../../services/cadastro/financeiro/ContaBancariaService'
import FoPagNeg from '../_FoPagNeg'
import { TedPixValidator } from './TedPixValidator'

const FoPagTedPix = (props) => {
  const {
    form,
    edicaoHabilitada,
    handleChangeCurrency,
    getBancos,
    getContasBancarias,
    setValorVeiculo,
    hide,
    isPix,
    isLojaPaga,
    excluir,
    isNew
  } = props

  const deveSelecionarVeiculo = props.negociacaoForm.values.veiculosConjunto.length > 0
  const [sugestaoDeConta, setSugestaoDeConta] = useState([])

  useEffect(() => {
    const onLoad = async () => {
      form.setFieldValue('veiculo', props.veiculo)

      await getBancos()
      await getContasBancarias()

      if (isNew) {
        form.setFieldValue('isLojaPaga', isLojaPaga)
      }

      form.setFieldValue('isPix', isPix)
    }
    onLoad()
  }, [])

  const onSelectConta = async (e) => {
    if (isLojaPaga) {
      await form.setFieldValue('banco', e.value)
      await form.setFieldValue('numeroBanco', e.value.codigo_ban)
    } else {
      await form.setFieldValue('banco', e.value)
      await form.setFieldValue('contaBancaria', e.value)
      await form.setFieldValue('numeroBanco', e.value.codban_cob)
      await form.setFieldValue('numeroAgencia', e.value.numage_cob)
      await form.setFieldValue('numeroConta', (e.value.numero_cob).toString())
      await form.setFieldValue('titular', e.value.nomeTitular)
    }
  }

  useEffect(() => {
    if (isLojaPaga) {
      const bancos = form.values.bancos.filter(
        conta => (conta.codigo_ban === form.values.numeroBanco)
      ).shift()
      form.setFieldValue('banco', bancos)
    } else {
      const contaBancariaEmp = form.values.contasBancarias.filter(
        conta => ((conta.numero_cob).toString() === form.values.numeroConta) && (conta.codban_cob === form.values.numeroBanco)
      ).shift()
      form.setFieldValue('banco', contaBancariaEmp)
    }
  }, [form.values.bancos, form.values.contasBancarias])

  const handleChangeContaBancaria = async (event) => {
    if (isLojaPaga) {
      await form.setFieldValue('banco', event.target.value)
      await form.setFieldValue('numeroBanco', event.target.value.codigo_ban)
    } else {
      await form.setFieldValue('banco', event.target.value)
      await form.setFieldValue('contaBancaria', event.target.value)
      await form.setFieldValue('numeroBanco', event.target.value.codban_cob)
      await form.setFieldValue('numeroAgencia', event.target.value.numage_cob)
      // await form.setFieldValue('numeroConta', (event.target.value.numero_cob).toString())
      await form.setFieldValue('titular', event.target.value.nomeTitular)
    }
  }

  const { isFormFieldValid, getFormErrorMessage } = useValidateInput(form)

  const sugerirConta = async () => {
    try {
      let conta
      if (isLojaPaga) {
        conta = await BancoService.filterAutocomplete(form.values.banco)
      } else {
        conta = await ContaBancariaService.filterAutocomplete(form.values.banco)
      }

      setSugestaoDeConta(conta)
    } catch (error) {
    }
  }
  return (
    <Modal
      header={isPix ? 'PIX' : 'TED'}
      btnSalvar={edicaoHabilitada ? form.submitForm : undefined}
      btnExcluir={(!isNew && edicaoHabilitada) ? excluir : undefined}
      width="55"
      visible={true}
      modal={true}
      onHide={hide}
    >
      <div className="formgrid grid">
        <div className="field col-12 flex justify-content-around mb-4">
          <ResponsavelPagamento isLojaPaga={isNew ? isLojaPaga : form.values.isLojaPaga} />
        </div>
        <div className="field col-12 md:col-6">
          {isLojaPaga
            ? <SearchInput
              value={form.values.banco?.descri_ban}
              onChange={handleChangeContaBancaria}
              field="descri_con"
              placeholder="- Selecione o banco -"
              name="banco"
              label="Banco"
              suggestions={sugestaoDeConta}
              onSelect={async (e) => { await onSelectConta(e) }}
              itemTemplate={BancoAutocompleteTemplate}
              completeMethod={sugerirConta}
              noFloatLabel
              disabled={!edicaoHabilitada}
              className="inputfield w-full"
              onDeleteModal={async () => await onSelectConta({})}
              selected={form.values.bancos }
              //Filtro modal
              filtrotitle="Pesquisa de banco"
              service={BancoService}
              model={ContaModel}
              primarykeyname="codigo_con"
              columns={[
                { campo: 'codigo_ban', nome: 'Número do banco' },
                { campo: 'descri_ban', nome: 'Banco' }
              ]}
            />
            : <SearchInput
              value={form.values.banco}
              onChange={handleChangeContaBancaria}
              field="descri_con"
              placeholder="- Selecione a conta destino -"
              name="banco"
              label="Conta de destino"
              suggestions={sugestaoDeConta}
              onSelect={async (e) => { await onSelectConta(e) }}
              itemTemplate={ContaAutocompleteTemplate}
              completeMethod={sugerirConta}
              noFloatLabel
              disabled={!edicaoHabilitada}
              className="inputfield w-full"
              onDeleteModal={async () => await onSelectConta({})}
              selected={form.values.bancos }
              //Filtro modal
              filtrotitle="Pesquisa de conta"
              service={ContaBancariaService}
              model={ContaModel}
              primarykeyname="codigo_con"
              columns={[
                { campo: 'descri_con', nome: 'Conta' },
                { campo: 'numero_cob', nome: 'Número da conta' }
              ]}
            /> }
        </div>
        <div className="field col-12 md:col-2">
          <TextInput
            name="numeroBanco"
            label="Nº do Banco"
            value={ form.values.numeroBanco || form.values.contaBancaria?.codban_cob}
            onChange={(e) => form.setFieldValue('numeroBanco', e.target.value)}
            disabled={!edicaoHabilitada || (edicaoHabilitada && isLojaPaga && Boolean(form.values.banco?.descri_ban)) || (edicaoHabilitada && !isLojaPaga)}
            keyfilter="int"
            className="inputfield w-full"
          />
        </div>
        <div className="field col-12 md:col-2">
          <TextInput
            name="numeroAgencia"
            label="Nº da Agência"
            value={form.values.numeroAgencia || form.values.contaBancaria?.numage_cob}
            onChange={(e) => form.setFieldValue('numeroAgencia', e.target.value)}
            disabled={!edicaoHabilitada || (edicaoHabilitada && !isLojaPaga)}
            keyfilter="int"
            className="inputfield w-full"
          />
        </div>
        <div className="field col-12 md:col-2">
          <TextInput
            name="numeroConta"
            label="Nº da Conta"
            value={form.values.numeroConta || form.values.contaBancaria?.numero_cob }
            onChange={(e) => form.setFieldValue('numeroConta', e.target.value) }
            disabled={!edicaoHabilitada || (edicaoHabilitada && !isLojaPaga)}
            keyfilter="int"
            className="inputfield w-full"
          />
        </div>
        <div className={'field col-12 md:col-' + (deveSelecionarVeiculo ? '4' : '6')}>
          <TextInput
            name="titular"
            label="Titular"
            value={form.values.titular?.toUpperCase()}
            disabled={!edicaoHabilitada || (edicaoHabilitada && !isLojaPaga)}
            onChange={form.handleChange}
            keyfilter={/[A-Z]|[a-z]|\s/}
            className="inputfield w-full"
          />
        </div>
        {deveSelecionarVeiculo && (
          <div className="field col-12 md:col-3">
            <RequiredLabel label="Veículo" valid={isFormFieldValid('veiculo.codigo_vei')} />
            <SelectInput
              value={form.values.veiculo}
              options={props.negociacaoForm.values.veiculosConjunto.map(veiculoNve => veiculoNve.veiculo)}
              onChange={(e) => form.setFieldValue('veiculo', e.target.value)}
              name='veiculo'
              optionLabel="placha"
              className={classNames({ 'p-invalid': isFormFieldValid('veiculo.codigo_vei') }, 'inputfield w-full')}
              dataKey="codigo_vei"
              disabled={!edicaoHabilitada}
              noFloatLabel
            />
            <FieldErrorMessage message={getFormErrorMessage('veiculo.codigo_vei')} />

          </div>
        )}
        <div className="field col-12 md:col-3">
          <RequiredLabel label="Data de Operação" valid={isFormFieldValid('dataEmissao')} />
          <DateInput
            name="dataEmissao"
            value={form.values.dataEmissao}
            disabled={!edicaoHabilitada}
            onChange={form.handleChange}
            onBlur={ () =>
              form.setFieldTouched('dataEmissao')
            }
            className={classNames({ 'p-invalid': isFormFieldValid('dataEmissao') }, 'inputfield w-full')}
          />
          <FieldErrorMessage message={getFormErrorMessage('dataEmissao')} />

        </div>
        <div className={'field col-12 md:col-' + (deveSelecionarVeiculo ? '2' : '3')}>
          <RequiredLabel label="Valor" valid={isFormFieldValid('valor')} />
          <TextInput
            name="valor"
            value={formatToCurrency(form.values.valor)}
            disabled={!edicaoHabilitada}
            onChange={handleChangeCurrency}
            onBlur={() => {
              setValorVeiculo()
              form.setFieldTouched('valor')
            }}
            className={classNames({ 'p-invalid': isFormFieldValid('valor') }, 'inputfield w-full')}
          />
          <FieldErrorMessage message={getFormErrorMessage('valor')} />
        </div>
        <div className="field col-12 md:col-12">
          <TextAreaInput
            name="observacoes"
            label="Observações"
            value={form.values.observacoes}
            disabled={!edicaoHabilitada}
            onChange={form.handleChange}
            className="inputfield w-full"
          />
        </div>
      </div>
    </Modal>
  )
}

export default (props) =>
  FoPagNeg({
    Component: FoPagTedPix,
    validationSchema: TedPixValidator,
    ...props
  })
