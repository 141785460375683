import ServiceBase from '../../base/ServiceBase'

export default class AprovacaoNegociacaoService {
  async get (id) {
    return await ServiceBase.getBase(`negociacao/${id}`)
  }

  async getAll (query, page, perPage) {
    return await ServiceBase.getBase(`aprovacaonegociacao?page=${page}&perPage=${perPage}`)
  }

  async filter (query, page, perPage) {
    return await ServiceBase.getBase(`aprovacaonegociacao?${query}&page=${page}&perPage=${perPage}`)
  }

  static async getQuantidade () {
    return await ServiceBase.getBase('aprovacaonegociacao/quantidade', false)
  }

  static async verificaPermissaoParaAprovarNegComManutencoesEmAberto (codigo_neg) {
    return await ServiceBase.putBase(`aprovacaonegociacao/permissao/manutencoesEmAberto/${codigo_neg}`)
  }

  static async verificaPermissaoParaAprovarNegComOrdemDeServicoEmAberto (codigo_neg) {
    return await ServiceBase.putBase(`aprovacaonegociacao/permissao/ordemDeServicoEmAberto/${codigo_neg}`)
  }

  static async aprovarNegociacao (codigo_neg) {
    return await ServiceBase.putBase(`aprovacaonegociacao/aprovar/${codigo_neg}`)
  }
}
