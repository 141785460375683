import React from 'react'
import Modal from '../../../../components/utils/Modal'

import { ReactComponent as CarroBloqueadoAtivo } from '../../../../assets/icones-analise-estoque/carro-bloqueado-ativado.svg'
import { ReactComponent as CarroBloqueadoDesativado } from '../../../../assets/icones-analise-estoque/carro-bloqueado-desativado.svg'
import { ReactComponent as CarroPatioAtivo } from '../../../../assets/icones-analise-estoque/carro-patio-ativado.svg'
import { ReactComponent as CarroPatioDesativado } from '../../../../assets/icones-analise-estoque/carro-patio-desativado.svg'
import { ReactComponent as CarroReservadoAtivo } from '../../../../assets/icones-analise-estoque/carro-reservado-ativado.svg'
import { ReactComponent as CarroReservadoDesativado } from '../../../../assets/icones-analise-estoque/carro-reservado-desativado.svg'
import { ReactComponent as EmDemonstracaoAtivo } from '../../../../assets/icones-analise-estoque/em-demonstracao-ativado.svg'
import { ReactComponent as EmDemonstracaoDesativado } from '../../../../assets/icones-analise-estoque/em-demonstracao-desativado.svg'
import { ReactComponent as KmAltaAtivo } from '../../../../assets/icones-analise-estoque/km-alta-ativado.svg'
import { ReactComponent as KmAltaDesativado } from '../../../../assets/icones-analise-estoque/km-alta-desativado.svg'
import { ReactComponent as OrdemServico } from '../../../../assets/icones-analise-estoque/ordem-servico.svg'
import CommonHelper from '../../../../helpers/CommonHelper'

function ModalAnaliseEstoqueAlertas ({ visible, onHide, alertaPatio, alertaOrdemServico, AlertaReservado, AlertaBloqueado, alertaDemonstracao, alertaKMAlta, diasPatio, clienteReserva, vendedorReserva, vendedorBloqueio, descricaoBloqueio, localizacaoDemonstracao, diasDemonstracao }) {
  function renderizaAlerta (Component, label, cor) {
    return <div className='flex'>
      < Component color={cor}/>  <p className='m-1'> {label} </p>
    </div>
  }
  return (
    <Modal
      visible={visible}
      onHide={onHide}
      header="Alertas"
      width={40}
    >
      <div className="flex flex-column">
        {alertaPatio
          ? renderizaAlerta(CarroPatioAtivo, ` No pátio há ${diasPatio} ${diasPatio > 1 ? 'dias' : 'dia'}. (Alerta a partir de 30 dias)`) : renderizaAlerta(CarroPatioDesativado, ' No pátio. (Alerta a partir de 30 dias)')
        }

        {alertaOrdemServico
          ? renderizaAlerta(OrdemServico, ' Ordens de serviço em aberto.', '#EC6A00') : renderizaAlerta(OrdemServico, ' Ordens de serviço em aberto.', '#B4B4B4') }
        {AlertaReservado
          ? renderizaAlerta(CarroReservadoAtivo, ` Reservado por ${vendedorReserva} para ${CommonHelper.removerCaracteresEspeciais(clienteReserva)}`) : renderizaAlerta(CarroReservadoDesativado, ' Reservado.') }

        {AlertaBloqueado
          ? renderizaAlerta(CarroBloqueadoAtivo, ` Bloqueado para venda por ${vendedorBloqueio}. (${descricaoBloqueio})`)
          : renderizaAlerta(CarroBloqueadoDesativado, ' Bloqueado. ')
        }

        {alertaDemonstracao
          ? renderizaAlerta(EmDemonstracaoAtivo, ` Em demonstração há ${diasDemonstracao} ${diasDemonstracao > 1 ? 'dias' : 'dia'} (${localizacaoDemonstracao})`)
          : renderizaAlerta(EmDemonstracaoDesativado, ' Em demonstração.')}

        {alertaKMAlta
          ? renderizaAlerta(KmAltaAtivo, ' KM alta - KMVeiculo / (AnoAtual - AnoFabricação) maior que 10.000')
          : renderizaAlerta(KmAltaDesativado, ' KM alta.')}

      </div>
    </Modal>
  )
}

export default ModalAnaliseEstoqueAlertas
