import React, { useEffect, useState } from 'react'
import Modal from '../../../../components/utils/Modal'
import FoPagDinheiro from './FoPagNeg/FoPagDinheiro/FoPagDinheiro'
import FoPagCartaoCredito from './FoPagNeg/FoPagCartaoCredito/FoPagCartaoCredito'
import FoPagCartaoDebito from './FoPagNeg/FoPagCartaoDebito/FoPagCartaoDebito'
import FoPagCheque from './FoPagNeg/FoPagCheque/FoPagCheque'
import FoPagTedPix from './FoPagNeg/FoPagTedPix/FoPagTedPix'
import FoPagFinanciamentoAberto from './FoPagNeg/FoPagFinanciamentoAberto/FoPagFinanciamentoAberto'
import FoPagFinanciamento from './FoPagNeg/FoPagFinanciamento/FoPagFinanciamento'
import FoPagPromissoria from './FoPagNeg/FoPagPromissoria/FoPagPromissoria'
import FoPagFinanciamentoDireto from './FoPagNeg/FoPagFinanciamentoDireto/FoPagFinanciamentoDireto'
import FoPagConsorcio from './FoPagNeg/FoPagConsorcio/FoPagConsorcio'
import FoPagFloorPlan from './FoPagNeg/FoPagFloorPlan/FoPagFloorPlan'
import FoPagVeiculoTroca from './FoPagNeg/FoPagVeiculoTroca/FoPagVeiculoTroca'
import BtnOption from '../../../../components/layout/BtnOption'
import { showErrorMessage, showWarnMessage } from '../../../../components/utils/Message'
import VeiculoService from '../../../../services/cadastro/veiculo/VeiculoService'

const FormasPagamentoModal = (props) => {
  const [pagamentoSelecionado, setPagamentoSelecionado] = useState({})

  useEffect(async () => {
    if (props.selectedPagamento?.tipoFormaPagamento?.codigo_tfp) {
      try {
        if (props.selectedPagamento.veitro_fpn) {
          const veiculoService = new VeiculoService()
          const veiculoTroca = await veiculoService.get(props.selectedPagamento.veitro_fpn)
          props.selectedPagamento.veiculoTroca = veiculoTroca
        }

        setPagamentoSelecionado(props.selectedPagamento)
      } catch (error) {
        showErrorMessage(error.message || 'Ocorreu um erro inesperado ao buscar o veículo da troca!')
      }
    }
  }, [props.selectedPagamento])

  const removePagamentoSelecionado = () => {
    setPagamentoSelecionado({})
    props.setSelectedPagamento({})
  }

  const LojaPaga = () => {
    return pagamentoSelecionado.tipoFormaPagamento ? pagamentoSelecionado.revpag_fpn === true : props.isLojaPaga
  }

  const showFoPagNeg = () => {
    const { edicaoHabilitada } = props
    const { pessoa } = props.negociacaoForm.values

    const veiculo = props.negociacaoForm.values.negociacaoVeiculo.veiculo
    let foPagNeg = 0

    if (pagamentoSelecionado.tipoFormaPagamento) {
      foPagNeg = pagamentoSelecionado.tipoFormaPagamento.codigo_tfp
    } else if (pagamentoSelecionado) {
      foPagNeg = parseInt(pagamentoSelecionado)
    }

    // Se possui um pagamento selecionado
    if (foPagNeg) {
      // Se existe veículos informado na negociacao
      if (veiculo) {
        switch (foPagNeg) {
        case 1:
          return <FoPagDinheiro
            veiculo={veiculo}
            negociacaoForm={props.negociacaoForm}
            dados={pagamentoSelecionado}
            adicionar={adicionarPagamento}
            editar={editarPagamento}
            excluir={excluirPagamento}
            hide={onHidePagamento}
            edicaoHabilitada={edicaoHabilitada}
            isLojaPaga={LojaPaga()}
          />

        case 2:
          return <FoPagCheque
            veiculo={veiculo}
            negociacaoForm={props.negociacaoForm}
            dados={pagamentoSelecionado}
            adicionar={adicionarPagamento}
            editar={editarPagamento}
            excluir={excluirPagamento}
            hide={onHidePagamento}
            edicaoHabilitada={edicaoHabilitada}
            isLojaPaga={LojaPaga()}
            pessoa={pessoa}
          />

        case 3:
          return <FoPagFinanciamento
            veiculo={veiculo}
            negociacaoForm={props.negociacaoForm}
            dados={pagamentoSelecionado}
            adicionar={adicionarPagamento}
            editar={editarPagamento}
            excluir={excluirPagamento}
            hide={onHidePagamento}
            edicaoHabilitada={edicaoHabilitada}
            isLojaPaga={LojaPaga()}
          />

        case 4:
          return <FoPagFinanciamentoAberto
            veiculo={veiculo}
            negociacaoForm={props.negociacaoForm}
            dados={pagamentoSelecionado}
            adicionar={adicionarPagamento}
            editar={editarPagamento}
            excluir={excluirPagamento}
            hide={onHidePagamento}
            edicaoHabilitada={edicaoHabilitada}
            isLojaPaga={LojaPaga()}
          />

        case 5:
          return <FoPagPromissoria
            veiculo={veiculo}
            negociacaoForm={props.negociacaoForm}
            dados={pagamentoSelecionado}
            adicionar={adicionarPagamento}
            editar={editarPagamento}
            excluir={excluirPagamento}
            hide={onHidePagamento}
            edicaoHabilitada={edicaoHabilitada}
            isLojaPaga={LojaPaga()}
            pessoa={pessoa}
          />

        case 7:
          return <FoPagTedPix
            veiculo={veiculo}
            negociacaoForm={props.negociacaoForm}
            dados={pagamentoSelecionado}
            adicionar={adicionarPagamento}
            editar={editarPagamento}
            excluir={excluirPagamento}
            hide={onHidePagamento}
            edicaoHabilitada={edicaoHabilitada}
            isLojaPaga={LojaPaga()}
            isPix={false}
          />

        case 8:
          return <FoPagFinanciamentoDireto
            veiculo={veiculo}
            negociacaoForm={props.negociacaoForm}
            dados={pagamentoSelecionado}
            adicionar={adicionarPagamento}
            editar={editarPagamento}
            excluir={excluirPagamento}
            hide={onHidePagamento}
            edicaoHabilitada={edicaoHabilitada}
            isLojaPaga={LojaPaga()}
          />

        case 9:
          return <FoPagConsorcio
            veiculo={veiculo}
            negociacaoForm={props.negociacaoForm}
            dados={pagamentoSelecionado}
            adicionar={adicionarPagamento}
            editar={editarPagamento}
            excluir={excluirPagamento}
            hide={onHidePagamento}
            edicaoHabilitada={edicaoHabilitada}
            isLojaPaga={LojaPaga()}
          />

        case 10:
          return <FoPagCartaoCredito
            veiculo={veiculo}
            negociacaoForm={props.negociacaoForm}
            dados={pagamentoSelecionado}
            adicionar={adicionarPagamento}
            editar={editarPagamento}
            excluir={excluirPagamento}
            hide={onHidePagamento}
            edicaoHabilitada={edicaoHabilitada}
            isLojaPaga={LojaPaga()}
          />

        case 11:
          return <FoPagCartaoDebito
            veiculo={veiculo}
            negociacaoForm={props.negociacaoForm}
            dados={pagamentoSelecionado}
            adicionar={adicionarPagamento}
            editar={editarPagamento}
            excluir={excluirPagamento}
            hide={onHidePagamento}
            edicaoHabilitada={edicaoHabilitada}
            isLojaPaga={LojaPaga()}
          />

        case 12:
          return <FoPagFloorPlan
            veiculo={veiculo}
            negociacaoForm={props.negociacaoForm}
            dados={pagamentoSelecionado}
            adicionar={adicionarPagamento}
            editar={editarPagamento}
            excluir={excluirPagamento}
            hide={onHidePagamento}
            edicaoHabilitada={edicaoHabilitada}
            isLojaPaga={LojaPaga()}
          />

        case 14:
          return <FoPagTedPix
            veiculo={veiculo}
            negociacaoForm={props.negociacaoForm}
            dados={pagamentoSelecionado}
            adicionar={adicionarPagamento}
            editar={editarPagamento}
            excluir={excluirPagamento}
            hide={onHidePagamento}
            edicaoHabilitada={edicaoHabilitada}
            isLojaPaga={LojaPaga()}
            isPix={true}
          />

        case 15:
          return <FoPagVeiculoTroca
            veiculo={veiculo}
            negociacaoForm={props.negociacaoForm}
            dados={pagamentoSelecionado}
            adicionar={adicionarPagamento}
            editar={editarPagamento}
            excluir={excluirPagamento}
            hide={onHidePagamento}
            edicaoHabilitada={edicaoHabilitada}
            isLojaPaga={LojaPaga()}
          />

        default:
          break
        }
      } else {
        showWarnMessage('É preciso informar veículos antes de informar a forma de pagamento!')
        removePagamentoSelecionado()
      }
    }
  }

  const onHidePagamento = () => {
    removePagamentoSelecionado()
  }

  const excluirPagamento = () => {
    const { negociacaoForm } = props
    const { foPagNegs } = negociacaoForm.values

    const indexFoPagNeg = foPagNegs.indexOf(pagamentoSelecionado)
    foPagNegs.splice(indexFoPagNeg, 1)

    props.atualizaTotalPagamentos()
    removePagamentoSelecionado()
  }

  const editarPagamento = async (dadosPagamento) => {
    const { negociacaoForm } = props
    const { foPagNegs } = negociacaoForm.values

    const indexFoPagNeg = foPagNegs.indexOf(pagamentoSelecionado)

    dadosPagamento.tipoFormaPagamento = pagamentoSelecionado.tipoFormaPagamento

    if (dadosPagamento.tipoFormaPagamento.codigo_tfp !== 3) {
      dadosPagamento.retorn_fpn = 0
    }

    foPagNegs.splice(indexFoPagNeg, 1, dadosPagamento)
    negociacaoForm.setFieldValue('foPagNegs', foPagNegs)

    props.atualizaTotalPagamentos()
    removePagamentoSelecionado()
  }

  const adicionarPagamento = async (dadosPagamento) => {
    const [tipoPagamento] = props.formasPagamento.filter(tipo => tipo.codigo_tfp === parseInt(pagamentoSelecionado))
    dadosPagamento.tipoFormaPagamento = tipoPagamento
    const { negociacaoForm } = props
    const { foPagNegs } = negociacaoForm.values

    if (dadosPagamento.tipoFormaPagamento.codigo_tfp !== 3) {
      dadosPagamento.retorn_fpn = 0
    }

    foPagNegs.push(dadosPagamento)

    const ordenarPagamentos = (primeiro, segundo) => {
      const primeiroIsLojaPaga = primeiro.revpag_fpn ? 'Pago pela loja' : 'Pago pelo cliente'
      const segundoIsLojaPaga = segundo.revpag_fpn ? 'Pago pela loja' : 'Pago pelo cliente'
      if (primeiroIsLojaPaga < segundoIsLojaPaga) {
        return 1
      }
      if (primeiroIsLojaPaga > segundoIsLojaPaga) {
        return -1
      }
      return 0
    }

    foPagNegs.sort(ordenarPagamentos)

    await negociacaoForm.setFieldValue('foPagNegs', foPagNegs)

    props.atualizaTotalPagamentos()
    removePagamentoSelecionado()
  }

  const handleSelectPagamento = (e) => {
    props.onHide()
    setPagamentoSelecionado(e.target.name)
  }

  const onHide = () => {
    removePagamentoSelecionado()
    props.onHide()
  }

  const formasDePagamento = props.formasPagamento.map((formaPagamento, indice) => {
    if (!props.isLojaPaga && formaPagamento.codigo_tfp === 4) {
      return
    }

    if (props.isLojaPaga && formaPagamento.codigo_tfp === 15) {
      return
    }

    if (!props.isVenda() && formaPagamento.codigo_tfp === 3) {
      return
    }

    return (
      <React.Fragment key={indice}>
        <BtnOption key={indice} onClick={(e) => handleSelectPagamento(e)} name={formaPagamento.codigo_tfp}>{formaPagamento.descri_tfp}</BtnOption>
        </React.Fragment>
    )
  })

  return (
    <>
      <Modal
        modal={true}
        header="Formas de Pagamento"
        visible={props.visible}
        className="pagamento-modal"
        onHide={onHide}
      >
        {formasDePagamento}
      </Modal>
      {showFoPagNeg()}
    </>
  )
}

export default FormasPagamentoModal
