import { Divider } from 'primereact/divider'
import React, { useEffect, useState } from 'react'
import TextInput from '../../../../components/inputs/TextInput'
import Modal from '../../../../components/utils/Modal'
import { formatCurrencyToNumber, formatToCurrency } from '../../../../helpers/formaters'

function ModalSimulacaoVenda ({ visible, onHide, veiculo, onSaveVeiculo = () => {} }) {
  const [state, setState] = useState({
    comissao: 0,
    valorDocumentacao: 0,
    precoVenda: 0,
    porcentagemTotalLucro: 0,
    custoTotalMaisCompra: 0
  })

  useEffect(() => {
    setState({
      comissao: state.comissao,
      valorDocumentacao: state.valorDocumentacao,
      precoVenda: veiculo.preco_pre,
      porcentagemTotalLucro: Number(veiculo.preco_pre) === 0 ? -100 : (Number(veiculo.totalLucroVenda) / Number(veiculo.preco_pre)) * 100,
      custoTotalMaisCompra: veiculo.custoTotalMaisCompra
    })
  }, [veiculo])

  useEffect(() => {
    calcularCustoTotal()
  }, [state.comissao, state.valorDocumentacao])

  const calcularCustoTotal = () => {
    const { comissao, valorDocumentacao } = state
    setState(prevState => ({
      ...prevState,
      custoTotalMaisCompra: veiculo.valorCompra + veiculo.valorjurosEstoque + veiculo.valorManutencao + veiculo.valorRateioDespesas + comissao + valorDocumentacao
    }))
  }

  function getDescricaoVeiculo () {
    if (veiculo.codcoc_vei) {
      return `${String(veiculo.descoc_vei).replace(/\//g, ' ')} ${veiculo.combustivel.descri_com}`
    }
    return `${veiculo.modelo.marca.descri_mar} ${veiculo.modelo.descri_mod} ${veiculo.carmod_vei ? veiculo.carmod_vei : ''} ${veiculo.combustivel.descri_com}`
  }

  const handleChangeCurrencyCustos = (event) => {
    const { name, value } = event.target
    const numericValue = formatCurrencyToNumber(value)
    setState(prevState => ({
      ...prevState,
      [name]: numericValue,
      precoVenda: prevState.precoVenda,
      custoTotalMaisCompra: calcularCustoTotal()
    }))
    handleChangePrecoVenda(event)
  }

  const handleChangePrecoVenda = (event) => {
    const { name, value } = event.target
    const numericValue = formatCurrencyToNumber(value)
    const novoLucro = numericValue - veiculo.custoTotalMaisCompra - state.comissao - state.valorDocumentacao
    const novaPorcentagemLucro = (novoLucro / numericValue) * 100
    const porcentagemTotalLucro = Number.isNaN(novaPorcentagemLucro)
      ? 0
      : novaPorcentagemLucro < -100
        ? -100
        : novaPorcentagemLucro > 100
          ? 100
          : novaPorcentagemLucro

    setState(prevState => ({
      ...prevState,
      [name]: numericValue,
      porcentagemTotalLucro: porcentagemTotalLucro

    }))
    veiculo.preco_pre = numericValue
  }

  return (
    <Modal
      visible={visible}
      onHide={onHide}
      header="Precificar"
      width={45}
      btnSalvar={() => onSaveVeiculo(veiculo)}
      btnSalvarLabel={'Atualizar preço'}
      btnExcluir={() => onHide()}
      btnExcluirLabel={'Cancelar'}
    >
      <div className="formgrid fluid mb-6">
        <div className="field col-12 mb-0">
          <TextInput disabled className='w-full' value={getDescricaoVeiculo()} label='Veículo' />
        </div>
        <Divider align="left" className='border'>
          <div className="align-items-center">
            <p className='text-sm font-bold drop-shadow'>Custos - {formatToCurrency(state.custoTotalMaisCompra)} </p>
          </div>
        </Divider>
        <div className='md:flex'>
          <div className="field col-12 md:col-4 sm:col-4">
            <TextInput disabled className='w-full' value={formatToCurrency(veiculo.valorCompra)} label='Valor da compra' />
          </div>
          <div className="field col-12 md:col-4 sm:col-4">
            <TextInput disabled className='w-full' value={formatToCurrency(veiculo.valorjurosEstoque)} label='Juros de estoque' />
          </div>
          <div className="field col-12 md:col-4 sm:col-4">
            <TextInput disabled className='w-full' value={formatToCurrency(veiculo.valorManutencao)} label='Manutenções' />
          </div>
        </div>
        <div className='md:flex'>
          <div className="field col-12 md:col-4 sm:col-4">
            <TextInput disabled className='w-full' value={formatToCurrency(veiculo.valorRateioDespesas)} label='Rateio de despesas' />
          </div>
          <div className="field col-12 md:col-4 sm:col-4">
            <TextInput className='w-full' name='comissao' onChange={handleChangeCurrencyCustos} value={formatToCurrency(state.comissao)} label='Comissão' />
          </div>
          <div className="field col-12 md:col-4 sm:col-4">
            <TextInput className='w-full' name='valorDocumentacao' onChange={handleChangeCurrencyCustos} value={formatToCurrency(state.valorDocumentacao)} label='Documentação' />
          </div>
        </div>
        <Divider align="left" className='border'>
          <div className="align-items-center">
            <p className='text-sm font-bold drop-shadow'>Definição de preço e lucro</p>
          </div>
        </Divider>
        <div className='md:flex'>
          <div className="field col-12 md:col-4 sm:col-4">
            <TextInput className='w-full' onChange={handleChangePrecoVenda} name='precoVenda' value={formatToCurrency(state.precoVenda)} label='Preço' />
          </div>
          <div className="field col-12 md:col-4 sm:col-4">
            <TextInput disabled className='w-full' value={formatToCurrency(state.precoVenda - veiculo.custoTotalMaisCompra - state.comissao - state.valorDocumentacao)} label='Lucro(R$)' />
          </div>
          <div className="field col-12 md:col-4 sm:col-4">
            <TextInput disabled className='w-full' name='lucroPorcentagem' value={Number(state.porcentagemTotalLucro).toFixed(2)} label='Lucro(%)' />
          </div>
        </div>
      </div>
    </Modal>
  )
}

export default ModalSimulacaoVenda
