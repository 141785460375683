import moment from 'moment'
import { formatPlacha } from '../../../helpers/formaters'

export const getNegociacaoDTO = (data = {}) => {
  let veiculoNve = data.vehiclesToSell ? data.vehiclesToSell : data.vehiclesToBuy
  if (veiculoNve) {
    if (veiculoNve.veiculo.acessorios[0]) {
      veiculoNve.veiculo.acessorios = veiculoNve.veiculo.acessorios[0].acessorio
    }
    veiculoNve.dahpreent_nve = veiculoNve?.dahpreent_nve ? new Date(veiculoNve.dahpreent_nve) : null
    veiculoNve.veiculo.produto.produtoEmpresas = data.produtoEmpresa
  }

  let veiculosConjunto

  if (data.veiculosConjunto) {
    veiculosConjunto = data.veiculosConjunto.map(veiculoNve => {
      return {
        ...veiculoNve,
        placha: formatPlacha(veiculoNve.veiculo.placa_vei, veiculoNve.veiculo.chassi_vei),
        veiculo: {
          ...veiculoNve.veiculo,
          placha: formatPlacha(veiculoNve.veiculo.placa_vei, veiculoNve.veiculo.chassi_vei)
        }
      }
    })
  }
  return {
    codope_neg: data.codope_neg || null,
    operac_neg: data.operac_neg || null,
    codapp_neg: data.codapp_neg || null,
    codigo_neg: data.codigo_neg || null,
    company_id: data.company_id || null,
    dat_neg: data.dat_neg || moment().format('YYYY-MM-DD HH:mm:ss') || null,
    status_neg: data.status_neg || '',
    descri_sne: data.descri_sne || '',
    motcan_neg: data.motcan_neg || '',
    obscon_neg: data.obscon_neg || '',
    documents: data.documents || [],
    procuradores: data.procuradores || [],
    foPagNegs: data.foPagNegs || [],
    usuins_neg: data.usuins_neg || '',
    pessoa: data.pessoa || {},
    vendedor: data.vendedor || { pessoa: {} },
    tipomidia: data.tipomidia || {},
    codtra_neg: data.codtra_neg || '',
    obscon_neg: data.obscon_neg || '',
    modalidade: data.modalidade || { codigo_mov: 1 },
    operacaoNeg: data.operacaoNeg || null,
    negociacaoVeiculo: veiculoNve || {},
    veiculosConjunto: veiculosConjunto || {},
    usuarioInseriu: data.usuarioInseriu || {},
    usuarioAprovou: data.usuarioAprovou || {},
    usuapr_neg: data.usuapr_neg || null,
    dahapr_neg: data.dahapr_neg || null,
    dahaprconsig_neg: data.dahaprconsig_neg || null,
    datfin_neg: data.datfin_neg || null,
    dahcon_neg: data.dahcon_neg || null,
    dahcan_neg: data.dahcan_neg || null,
    dahalt_neg: data.dahalt_neg || null,
    representantePJ: data.representantePJ || {},
    obs_neg: data.obs_neg || '',
    valorAcessoriosNeg: data.valorAcessoriosNeg,
    valorServicosNeg: data.valorServicosNeg,
    veiculosRestantesVenda: data.veiculosRestantesVenda,
    veiculosRestantesCompra: data.veiculosRestantesCompra,
    vehiclesToSell: data.vehiclesToSell ? veiculoNve : undefined,
    vehiclesToBuy: data.vehiclesToBuy ? veiculoNve : undefined,
    procurador: data.procurador || [],
    procuradorVeiculo: data.procurador || [],
    destinoPagar: data.destinoPagar || {},
    destinoReceber: data.destinoReceber || {},
    proprietario: {},
    entreguePara: {}

  }
}

export const postNegociacaoDTO = (data) => {
  let veiculosParaVender = undefined
  if (data.vehiclesToSell) {
    if (data.vehiclesToSell.length) {
      veiculosParaVender = data.vehiclesToSell.map(veiculo => {
        return {
          ...veiculo,
          ignfat_nve: null,
          dahpreent_nve: veiculo?.dahpreent_nve ? moment(veiculo?.dahpreent_nve).format('YYYY-MM-DD HH:mm:ss') : null
        }
      })
    } else {
      veiculosParaVender = {
        ...data.vehiclesToSell,
        ignfat_nve: null,
        dahpreent_nve: data.vehiclesToSell?.dahpreent_nve ? moment(data.vehiclesToSell?.dahpreent_nve).format('YYYY-MM-DD HH:mm:ss') : null
      }
    }
  }

  let veiculosParaComprar = undefined

  if (data.vehiclesToBuy) {
    if (data.vehiclesToBuy.length) {
      veiculosParaComprar = data.vehiclesToBuy.map(veiculo => {
        return {
          ...veiculo,
          dahpreent_nve: veiculo?.dahpreent_nve ? moment(veiculo?.dahpreent_nve).format('YYYY-MM-DD HH:mm:ss') : null,
          consig_nve: veiculo?.consig_nve ? 1 : 0,
          codfoa_nve: veiculo?.codfoa_nve,
          ignfat_nve: null
        }
      })
    } else {
      veiculosParaComprar = {
        ...data.vehiclesToBuy,
        dahpreent_nve: data.vehiclesToBuy?.dahpreent_nve ? moment(data.vehiclesToBuy?.dahpreent_nve).format('YYYY-MM-DD HH:mm:ss') : null,
        consig_nve: data.vehiclesToBuy?.consig_nve ? 1 : 0,
        codfoa_nve: data.vehiclesToBuy?.codfoa_nve,
        ignfat_nve: null
      }
    }
  }

  if (data.documents) {
    data.documents.forEach((doc, index) => {
      if (data.documents[index].datpag_ndo) data.documents[index].datpag_ndo = moment(doc.datpag_ndo).format('YYYY-MM-DD')
      if (data.documents[index].datemi_ndo) data.documents[index].datemi_ndo = moment(doc.datemi_ndo).format('YYYY-MM-DD')
    })
  }
  return {
    codigo_neg: data.codigo_neg,
    dat_neg: moment(data.dat_neg).format('YYYY-MM-DD HH:mm:ss') || moment().format('YYYY-MM-DD HH:mm:ss') || null,
    status_neg: data.status_neg || 1,
    codven_neg: data.vendedor.codigo_ven || '',
    codpes_neg: data.pessoa.codigo_pes || data.pessoa.codigo || '',
    codope_neg: data.codope_neg || '',
    motcan_neg: data.motcan_neg || '',
    obscon_neg: data.obscon_neg || '',
    codmov_neg: data.modalidade.codigo_mov || 1,
    codtim_neg: data.tipomidia?.codigo_tim || null,
    vehiclesToSell: veiculosParaVender,
    vehiclesToBuy: veiculosParaComprar,
    documents: data.documents || [],
    procuradores: data.procurador || [],
    foPagNegs: data.foPagNegs || [],
    codrep_neg: data.representantePJ.codigo_pef || data.representantePJ.codigo_pes || null,
    obs_neg: data.obs_neg || '',
    ajuplu_neg: null,
    usuapr_neg: data.usuapr_neg,
    dahapr_neg: data.dahapr_neg,
    procurador: data?.procurador || [],
    procuradorVeiculo: data?.procurador || [],
    destinoPagar: data.destino || {},
    destinoReceber: data.destino || {},
    proprietario: data.proprietario || {}
  }
}
