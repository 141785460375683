import { Component } from 'react'
import styled from 'styled-components'
import EmpresasService from '../../services/cadastro/pessoa/EmpresasService'
import ConfigService from '../../services/configuracao/ConfigService'
import ImagemService from '../../services/imagem/ImagemService'
import { getCompany } from '../../helpers/auth'

const StyledDashboard = styled.div`
  display: flex;
  height: 90vh;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  overflow: hidden;
  padding-bottom: 0;

  h1 {
    font-size: 2em;
  }

  @media (max-width: 768px) {
    img {
      width: 100%;
      object-fit: contain;
    }
    h1 {
    font-size: 1.5em;
    text-align: center;
  }
  }
`

export class Dashboard extends Component {
  constructor(props) {
    super(props)
    this.state = {
      configValue: null,
      imageLoadError: false
    }
  }

  async componentDidMount() {
    try {
      const empresa = await EmpresasService.getEmpresaLogada()
      const config = await ConfigService.getValor(3134)
      const logo = await ImagemService.getImages('codigo_pej', empresa?.codigo_emp)

      if (logo[0] !== undefined) {
        this.setState({ configValue: logo[0].url })
      } else {
        this.setState({ configValue: config })
      }
    } catch (error) {
      console.error('Erro ao obter valor da configuração:', error)
    }
  }

  handleImageError() {
    this.setState({ imageLoadError: true })
  }

  render() {
    const { configValue, imageLoadError } = this.state
    return (
      <StyledDashboard>
        {(imageLoadError || (imageLoadError && !CommonHelper.isDesktop())) && <h1>{getCompany().apelid_pes}</h1>}
        {configValue && !imageLoadError && (
          <img src={configValue} onError={() => this.handleImageError()} />
        )}
      </StyledDashboard>
    )
  }
}
