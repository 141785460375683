import * as Yup from 'yup'

export const TedPixValidator = Yup.object().shape({
  veiculo: Yup.object().shape({
    codigo_vei: Yup.number()
      .nullable()
      .required('O veículo não foi selecionado!')
  }),
  valor: Yup.number()
    .nullable()
    .required('O valor não foi informado!')
    .min(0.001, 'O valor não foi informado!'),
  dataEmissao: Yup.date()
    .nullable()
    .required('A data da operação não foi informada!')

})
