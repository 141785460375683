import ServiceBase from '../base/ServiceBase'

export default class IntegracaoUsuarioService {
  static async enviarEmailIntegracao ({ organizacao, email, login, senha, url }) {
    return await ServiceBase.postBase('integracaousuario/email', {
      organizacao,
      email,
      login,
      senha,
      url
    })
  }

  static async integrarContas (dados) {
    return await ServiceBase.postBase('integracaousuario', { dados })
  }
}
